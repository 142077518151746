import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  IconButton,
  Menu as MUIMenu,
  Menu,
  MenuItem,
  RadioGroup,
  Slider as SliderMUI,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import {
  ArrowLeft,
  CaretDown,
  Copy,
  FadersHorizontal,
  ForkKnife,
  Pen,
  Plus,
  WarningCircle,
  X,
} from "@phosphor-icons/react";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import {
  GETAPI_OrderTypesCsr,
  POSTAPI_CSR_ADMIN,
  POSTAPI_CreateInfoCsrAdmin,
} from "../../Auth/Index";
import {
  copyTrackingUrlDetails,
  copyUserDetails,
  formatDate,
  formatDateTimeNext,
  formatTime,
  getSpiceText,
  getSpiceValue,
  statusButtonClass,
} from "../../Components/Common";
import {
  AdOrderEditDeleteMenuCsr,
  AlertErrorCsr,
  CartQuantityAdminArrCsr,
  CartQuantityCsr,
  CsrModal,
  CsrSwitch,
  CustomCheckbox,
  CustomFormControlLabel,
  CustomRadio,
  DataGridNovaCsr,
  InputButtonCsr,
  InputButtonSearchCsr,
  InputCompTextAreaCsr,
  InputLabelCsr,
  MUIMenuTwo,
  ReactSelectDropCsr,
  getDeliveryLocationStatusIconCsr,
  getPriorityLevelIconCSR,
  getPriorityLevelTextCSR,
} from "../../Components/CommonCsr";
import { OrderViewTableCsr } from "../../Components/MuiTable";
import ting from "../../Components/Ting.mp3";
import {
  ValidateLetAddItemBtn,
  isTextEmpty,
} from "../../Components/Validate_Admin";
import { ORDER_ORIGIN } from "../../Components/constants";
import { getS3Image } from "../../Components/s3";
import { ORDER_STATUS, PRIORITY_LEVEL } from "../../Components/staticData";
import "../../css/Csr/csr_dashboard.css";
import sun from '../../assets/sun.svg'
import moon from '../../assets/moon.svg'
import { SvgButtonCSR } from "./CsrLayout";

const style = {
  position: "absolute",
  width: "400px",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "black",
  boxShadow: 24,
  maxHeight: "95vh",
  overflowX: "hidden",
  overflowY: "auto",
  borderRadius: "0px",
  scrollbarWidth: "thin",
  padding: 3,
  color: "white",
};

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "#000000",
    borderRadius: 0,
    border: "1px solid #545356",
    fontFamily: "Blender Pro thin",
    outline: "none",
    boxShadow: state.isFocused ? "none !important" : "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#333333" : "transparent",
    fontFamily: "Blender Pro thin",
    // border: "1px solid #545356",
    color: "white",
    borderRadius: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: "white",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "#000000",
    borderRadius: 0,
    marginTop: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    color: "white",
    fontFamily: "Blender Pro thin",
  }),
};

const csrTheme = createTheme({
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          width: "50px",
          height: "50px",
          backgroundColor: "transparent",
          backgroundImage: `url("https://nova-foods.s3.amazonaws.com/miscellaneous/emojione_fire.svg")`,
          boxShadow: "none",
          transition: "none",
          border: "none",
          "::before": {
            boxShadow: "none",
            transition: "none",
          },
          ":hover": {
            boxShadow: "none",
            transition: "none",
          },
          "&.Mui-active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #545356", // Change border color
          borderRadius: "0px", // Make square
          backgroundColor: "black", // Change background color
          padding: "12px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: "0",
          minHeight: "0px",
          "&.Mui-expanded": {
            minHeight: "0px",
          },
        },
        content: {
          margin: "0px",
          "&.Mui-expanded": {
            margin: "0px",
          },
        },
        expandIconWrapper: {
          color: "white", // Change icon color
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: "black",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "21px",
          color: "white",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "white",
          "& .MuiSelect-icon": {
            color: "white",
          },
        },
      },
    },
  },

  shape: {
    borderRadius: 0, // Remove border radius for all components
  },
  // Additional global overrides for backgrounds
  overrides: {
    MuiModal: {
      root: {
        backgroundColor: "black", // Change modal background color
      },
    },
  },
});

export const CreateOrder = ({ fetchOrder, setFetchOrder, setView }) => {
  const [values, setValues] = useState({
    itemsArr: [],
    orderId: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    orderTypeId: "",
    deliveryPartners: [],
    note: "",
    dispatchNote : '',
    needCutlery: false,
    itemModalVisible: false,
    spiceLevel: 28,
    item: null,
    thisItem: null,
    allItems: [],
    dineInItems : [],
    combo: null,
    allCombos: [],
    thisCombo: null,
    errorPosition: "",
    errorText: "",
    first: true,
    loading: false,
    // mapCenter: [27.7172, 85.3240],
    add_lat: "",
    add_lng: "",
    add_address: null,
    add_id: "",
    // modal
    type: "",
    locationStatus: "",
    allModifiers: [],
    modifiersSel: [],
    quanSel: 1,
    add_item_loading: false,
    editItemModal: false,
    currentItem: null,
    customDiscount: { discountType: "percent", discountValue: null },
    customDeliveryCharge: null,
    subTotalPrice: null,
    thisCustomItem: null,
    mapUrl: null,
    taxes: [],
    salesType: ORDER_ORIGIN.ADMIN,
  });
  const {
    itemsArr,
    name,
    phone,
    email,
    // address,
    orderTypeId,
    deliveryPartners,
    locationStatus,
    note,
    dispatchNote,
    needCutlery,
    itemModalVisible,
    spiceLevel,
    item,
    thisItem,
    allItems,
    dineInItems,
    currentItem,
    // combo,
    allCombos,
    thisCombo,
    errorPosition,
    errorText,
    first,
    thisCustomItem,
    // mapCenter,
    add_lat,
    add_lng,
    add_address,
    //  add_id,
    type,
    // allModifiers,
    modifiersSel,
    quanSel,
    add_item_loading,
    loading,
    customDiscount,
    customDeliveryCharge,
    subTotalPrice,
    mapUrl,
    taxes,
    salesType
  } = values;
  const [add_edit_visible_ind, Set_add_edit_visible] = useState(null);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showEditModifiersModal, setShowEditModifiersModal] = useState(false);
  const [coordinates, setCoordinates] = useState({ data: "" });

  const calculateTotalItemPrices = useMemo(() => {
    return () => {
      let totalItemPrices = 0;

      itemsArr.forEach((prod) => {
        let totalCostModifiers = 0;

        if (prod.modifiers) {
          for (const modifier of prod.modifiers) {
            for (const option of modifier.options) {
              if (option.sel) {
                totalCostModifiers += option.price;
              } else {
                totalCostModifiers += 0;
              }
            }
          }
        }

        const percentPrice =
          prod.discount && prod.discount.value
            ? Math.ceil(
                parseFloat(
                  Math.ceil(parseFloat(prod?.price)) -
                    (Math.ceil(parseFloat(prod?.discount?.value)) / 100) *
                      Math.ceil(parseFloat(prod?.price)),
                ),
              )
            : 0;
        const percentPriceWithModifiers =
          percentPrice +
          (prod?.modifiers?.length > 0
            ? Math.ceil(parseFloat(totalCostModifiers))
            : 0);
        const valuePrice =
          prod.discount && prod.discount.value
            ? Math.ceil(
                parseFloat(
                  Math.ceil(parseFloat(prod?.price)) -
                    Math.ceil(parseFloat(prod.discount?.value)),
                ),
              )
            : 0;
        const valuePriceWithModifiers =
          valuePrice +
          (prod?.modifiers?.length > 0
            ? Math.ceil(parseFloat(totalCostModifiers))
            : 0);

        if (
          prod.discount &&
          prod.discount.type === "percent" &&
          prod.discount.value !== null &&
          prod.discount.value > 0
        ) {
          totalItemPrices += Math.ceil(
            parseFloat(percentPriceWithModifiers * prod.quantity),
          );
        } else if (
          prod.discount &&
          prod.discount.type === "value" &&
          prod.discount.value !== null &&
          prod.discount.value > 0
        ) {
          totalItemPrices += Math.ceil(
            parseFloat(valuePriceWithModifiers * prod.quantity),
          );
        } else {
          totalItemPrices += Math.ceil(
            parseFloat(
              (Math.ceil(parseFloat(prod.price)) +
                Math.ceil(parseFloat(totalCostModifiers))) *
                prod.quantity,
            ),
          );
        }
      });

      setValues((x) => ({
        ...x,
        subTotalPrice: Math.ceil(parseFloat(totalItemPrices)),
      }));
    };
  }, [itemsArr]);

  useEffect(() => {
    calculateTotalItemPrices();
  }, [itemsArr, calculateTotalItemPrices]);

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoCsrAdmin().then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            allItems: res.items,
            dineInItems: res.dineInItems,
            allCombos: res.combos,
            allItemsCombos: res.items.concat(res.combos),
            first: false,
          }));
        }
      });

      GETAPI_OrderTypesCsr(`/order/orderType`).then((res) => {
        if (res.success) {
          res.orderTypes.forEach((orderType) => {
            if (orderType.isDefaultPartner === true) {
              setValues((x) => ({ ...x, orderTypeId: orderType._id }));
            }
          });

          setValues((prev) => ({
            ...prev,
            deliveryPartners: res.orderTypes,
          }));
        }
      });

      GETAPI_OrderTypesCsr(`/settings`).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            taxes: res.taxes,
          }));
        }
      });
    }
  }, [first]);

  //returns boolean checking order type is default partner or not
  const selectedPartner = useMemo(() => {
    if (deliveryPartners.length === 0) return false;
    return deliveryPartners.find((partner) => partner._id === orderTypeId)
      ?.isDefaultPartner;
  }, [orderTypeId, deliveryPartners]);

  const handleDiscountTypeChange = (e) => {
    setValues((x) => ({
      ...x,
      customDiscount: {
        discountType: e.target.value,
        discountValue: customDiscount.discountValue,
      },
    }));
  };

  const handleDiscountValueChange = (e) => {
    setValues((x) => ({
      ...x,
      customDiscount: {
        discountType: customDiscount.discountType,
        discountValue: e.target.value,
      },
    }));
  };

  const applyDiscount = () => {
    if (!customDiscount.discountValue && !customDiscount.discountType) {
      return;
    }

    if (customDiscount.discountType === "value") {
      if (
        parseFloat(customDiscount.discountValue) > parseFloat(currentItem.price)
      ) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Value can't be more than the product price.",
        }));
        return;
      }
      if (parseFloat(customDiscount.discountValue) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Value can't be less than 0.",
        }));
        return;
      }
    }

    if (customDiscount.discountType === "percent") {
      if (parseFloat(customDiscount.discountValue) > 100) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Can't apply more than 100%.",
        }));
        return;
      }
      if (parseFloat(customDiscount.discountValue) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Cant't apply less than 0.",
        }));
        return;
      }
    }

    const updatedArr = itemsArr.map((item) => {
      if (item.itemId && !item.identifierId) {
        if (item.itemId === currentItem.itemId && !currentItem.identifierId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.itemId && item.identifierId) {
        if (
          item.itemId === currentItem.itemId &&
          item.identifierId === currentItem.identifierId
        ) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.comboId && !item.identifierId) {
        if (item.comboId === currentItem.comboId && !currentItem.identifierId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.comboId && item.identifierId) {
        if (
          item.comboId === currentItem.comboId &&
          item.identifierId === currentItem.identifierId
        ) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.customId) {
        if (item.customId === currentItem.customId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        }
      }
      return item;
    });

    setValues((x) => ({
      ...x,
      errorPosition: "",
      errorText: "",
    }));
    setValues((x) => ({ ...x, itemsArr: updatedArr }));
    setShowDiscountModal(false);
    Set_add_edit_visible(null);
    setValues((x) => ({
      ...x,
      customDiscount: { discountType: "percent", discountValue: null },
    }));
  };

  const handleAddItemBtn = () => {
    // var passValidation =
    //   orderTypeId === "" ? false : selectedPartner ? false : true;
    var passValidation = true;
    var validated = ValidateLetAddItemBtn(
      orderTypeId,
      name,
      phone,
      add_lat,
      add_lng,
      setValues,
      passValidation,
    );
    if (!validated) return;
    setValues((x) => ({
      ...x,
      itemModalVisible: true,
      errorPosition: "",
      errorText: "",
    }));
  };

  useEffect(() => {
    if (itemsArr.length === 0) {
      setValues((x) => ({ ...x, customDeliveryCharge: null }));
    }
  }, [itemsArr]);

  const closeModal = () => {
    setValues((val) => ({
      ...val,
      itemModalVisible: false,
      editItemModal: false,
      type: "",
      spiceLevel: 28,
      item: null,
      modifiersSel: [],
      quanSel: 1,
      thisItem: null,
      thisCombo: null,
      add_item_loading: false,
      thisCustomItem: null,
      errorPosition: "",
      errorText: "",
    }));
  };

  // single item select drop handle (for item select)
  const ItemSelectWithDetails = (e) => {
    const allItemsData = (salesType===ORDER_ORIGIN.DAY_SALES) ? dineInItems : allItems 
    var thisItem;
    var refer;
    thisItem = allItemsData.filter((x) => x._id === e.value);
    thisItem = thisItem.length > 0 ? thisItem[0] : null;
    if (!thisItem) return;
    refer = JSON.parse(JSON.stringify(thisItem));
    var allModifiersData = refer.modifiers;
    setValues((prev) => ({
      ...prev,
      item: e.value,
      thisItem: thisItem,
      allModifiers: allModifiersData,
      modifiersSel: allModifiersData,
    }));
  };

  const ComboSelected = (e) => {
    var thisComboItem;
    var refer;
    thisComboItem = allCombos.filter((x) => x._id === e.value);
    thisComboItem = thisComboItem.length > 0 ? thisComboItem[0] : null;
    if (!thisComboItem) return;
    refer = JSON.parse(JSON.stringify(thisComboItem));
    setValues((prev) => ({
      ...prev,
      item: e.value,
      thisCombo: refer,
    }));
    // handleChangeFun(mainValues => ({ ...mainValues, [varName]: e.value }))
  };

  const modifierSelected = (modInd, optionInd) => {
    var nowMods = [...modifiersSel];
    var selected = nowMods[modInd].options[optionInd];
    selected.sel = selected.sel ? false : true;
    setValues((prev) => ({ ...prev, modifiersSel: nowMods }));
  };

  const modifierSelectedEdit = (modIndex, optionsIndex) => {
    const newModifiers = _.cloneDeep(modifiersSel);
    const selected = newModifiers[modIndex].options[optionsIndex];
    selected.sel = !selected.sel;
    setValues((prev) => ({ ...prev, modifiersSel: newModifiers }));
  };

  const handleChangeSlide = (e, newVal) => {
    setValues((x) => ({ ...x, spiceLevel: newVal }));
  };

  // add item button at end
  const addItem = () => {
    if (type === "") return;
    if (type === "Combo Item" && !thisCombo) {
      return;
    }
    if (type === "Item" && !thisItem) {
      return;
    }
    if (type === "Custom Item" && !thisCustomItem) {
      return;
    }
    if (type === "Custom Item" && thisCustomItem) {
      if (!thisCustomItem.name || !thisCustomItem.price) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide name and price.",
        }));
        return;
      }
    }
    let itemExists = false;
    let comboExists = false;

    setValues((x) => ({ ...x, add_item_loading: true }));
    if (thisCustomItem) {
      //custom item
      let newCustomItem = {
        customId: uuidv4(),
        name: thisCustomItem.name,
        price: thisCustomItem.price,
        unitPrice: thisCustomItem.price,
        quantity: quanSel,
        spiceLevel: null,
        hasSpiceLevel: false,
        modifiers: [],
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, newCustomItem],
      }));
      closeModal();
      return;
    }
    var thisItem_a = thisItem;
    var send_data = {
      name,
      phone,
      email,
      address: add_address,
      latitude: add_lat,
      longitude: add_lng,
      needCutlery,
      note,
      dispatchNote,
      orderTypeId,
    };
    var itemInfo = {};
    var comboInfo = {};

    if (thisItem_a) {
      //item
      const productExists = itemsArr.some(
        (item) => item.itemId === thisItem_a._id,
      );
      if (productExists) {
        if (
          !item.modifiers &&
          thisItem_a?.modifiers?.length === 0 &&
          thisItem_a?.hasSpiceLevel === false
        ) {
          setValues((x) => ({ ...x, add_item_loading: true }));
          closeModal();
          return;
        }
        itemExists = true;
      }
      thisItem_a.spiceLevel = getSpiceText(spiceLevel);
      var modsArr = [];
      if (modifiersSel.length > 0) {
        modifiersSel.forEach((element) => {
          var thisEl = { ...element };
          thisEl.options = [];
          element.options.forEach((op) => {
            // if (op.sel) {
            //   thisEl.options.push({
            //     name: op.name,
            //     price: op.price,
            //   });
            // }
            thisEl.options.push({
              name: op.name,
              price: op.price,
              sel: op.sel === true ? true : false,
            });
          });
          if (thisEl.options.length > 0) {
            modsArr.push(thisEl);
          }
        });
      }
      itemInfo = {
        itemId: thisItem_a._id,
        quantity: quanSel,
        spiceLevel: thisItem.hasSpiceLevel ? getSpiceText(spiceLevel) : null,
        modifiers: modsArr,
        image: thisItem_a.image,
        name: thisItem_a.name,
        price: thisItem_a.price,
        hasDiscount: thisItem_a.hasDiscount,
        discountedPrice: thisItem_a.discountedPrice,
        hasSpiceLevel: thisItem_a.hasSpiceLevel,
      };
      if (itemExists) {
        itemInfo.identifierId = uuidv4();
      }
      send_data = {
        ...send_data,
        itemInfo,
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, send_data.itemInfo],
      }));
      closeModal();
    } else {
      //combo

      thisItem_a = allCombos.filter((x) => x._id === item);
      if (thisItem_a.length === 0) return;
      thisItem_a = thisItem_a[0];
      const productExists = itemsArr.some(
        (item) => item.comboId === thisItem_a._id,
      );
      if (productExists) {
        if (
          !item.modifiers &&
          thisItem_a?.modifiers?.length === 0 &&
          thisItem_a?.hasSpiceLevel === false
        ) {
          setValues((x) => ({ ...x, add_item_loading: true }));
          closeModal();
          return;
        }
        comboExists = true;
      }
      comboInfo = {
        comboId: thisItem_a._id,
        quantity: quanSel,
        spiceLevel: getSpiceText(spiceLevel),
        image: thisItem_a.image,
        name: thisItem_a.name,
        price: thisItem_a.price,
        hasDiscount: thisItem_a.hasDiscount,
        hasSpiceLevel: thisItem_a.hasSpiceLevel,
        discountedPrice: thisItem_a.discountedPrice,
      };
      if (comboExists) {
        comboInfo.identifierId = uuidv4();
      }
      send_data = {
        ...send_data,
        comboInfo,
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, send_data.comboInfo],
      }));
      closeModal();
    }
  };

  // edit clicked in menu
  const editItemFn = (prod) => {
    if (prod.itemId) {
      var modTestArr = [];
      prod.allModifiers.forEach((element, ind) => {
        var selected = null;
        prod.modifiers.filter((x) => {
          if (x.modifierId === element.modifierId) {
            selected = x;
          }
          return selected;
        });
        if (selected) {
          //has modifier selected
          selected.options.forEach((op, i) => {
            var index = element.options.findIndex(
              (obj) => obj.name === op.name,
            );
            op.sel = true;
            element.options[index] = op;
          });
          modTestArr.push(element);
        } else modTestArr.push(element);
      });
      prod.modifiers = modTestArr;
      setValues((prev) => ({
        ...prev,
        item: prod.itemId,
        thisItem: prod,
        allModifiers: prod.allModifiers,
        modifiersSel: prod.modifiers,
        editItemModal: true,
        errorPosition: "",
        errorText: "",
        spiceLevel: prod.spiceLevel ? getSpiceValue(prod.spiceLevel) : null,
      }));
    } else if (prod.comboId) {
      setValues((prev) => ({
        ...prev,
        item: prod.comboId,
        thisCombo: prod,
        editItemModal: true,
        errorPosition: "",
        errorText: "",
        spiceLevel: prod.spiceLevel ? getSpiceValue(prod.spiceLevel) : null,
        quanSel: prod.quantity,
      }));
    } else return;
  };

  const updateFnAPI = () => {
    if (currentItem.itemId && !currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.itemId === currentItem.itemId && !item.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
              modifiers: modifiersSel?.length > 0 ? modifiersSel : [],
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr, modifiersSel: null }));
    }

    if (currentItem.itemId && currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.itemId === currentItem.itemId &&
        item.identifierId === currentItem.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
              modifiers: modifiersSel?.length > 0 ? modifiersSel : [],
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr, modifiersSel: null }));
    }

    if (currentItem.comboId && !currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.comboId === currentItem.comboId && !item.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr }));
    }

    if (currentItem.comboId && currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.comboId === currentItem.comboId &&
        item.identifierId === currentItem.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr }));
    }

    setShowEditModifiersModal(false);
    setValues((x) => ({ ...x, spiceLevel: 28 }));
  };

  useEffect(() => {
    const phonePattern = /^[9][6-9]\d{8}$/;
    if (selectedPartner && phonePattern.test(phone)) {
      POSTAPI_CSR_ADMIN("/user/checkValidUser", {
        contact: phone,
      }).then((res) => {
        if (res.success) {
          if (res?.isSystemUser && res?.user?.isBanned) {
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: "This phone number is blacklisted",
            }));
          }
        }
      });
    }
  }, [phone, selectedPartner]);

  const createFn = async () => {
    try {
    if (!orderTypeId) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Please provide a order type",
      }));
      return;
    }

    if (itemsArr.length === 0) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Please provide an item",
      }));
      return;
    }

    if (selectedPartner) {
      // const phonePattern = /^(98|97|96|985|984|980)\d{7}$/;
      // const phonePattern = /^(?:\(?\+977\)?)?[9][6-9]\d{8}|01[-]?[0-9]{7}$/;
      const phonePattern = /^[9][6-9]\d{8}$/;

      if (phone === "") {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide phone number",
        }));
        return;
      }
      if (!phonePattern.test(phone)) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide a valid phone number",
        }));
        return;
      }
    }

    // reomve sel proprty and also remove those with sel prperty
    const updatedItemsArr = itemsArr.map((item) => {
      if (item.modifiers && item.modifiers.length > 0) {
        // Filter out options with sel set to false and remove the sel property
        const updatedModifiers = item.modifiers.map((modifier) => ({
          ...modifier,
          options: modifier.options
            .filter((option) => option.sel)
            .map(({ sel, ...rest }) => rest),
        }));
        // Remove modifiers with no selected options
        const filteredModifiers = updatedModifiers.filter(
          (modifier) => modifier.options.length > 0,
        );
        return {
          ...item,
          modifiers: filteredModifiers,
        };
      }
      return item;
    });

    const itemsInfo = [];
    const combosInfo = [];
    const customItemsInfo = updatedItemsArr
      .filter((item) => item?.customId)
      .map((item) => {
        const { spiceLevel, hasSpiceLevel, modifiers, ...rest } = item;
        // Here, return the filtered properties inside an object
        return { ...rest };
      });

    updatedItemsArr.forEach((item) => {
      if (!item?.customId) {
        if (item.itemId) {
          itemsInfo.push(item);
        } else if (item.comboId) {
          combosInfo.push(item);
        }
      }
    });

    setValues((x) => ({ ...x, loading: true }));
    let data = selectedPartner
      ? {
          orderTypeId,
          name,
          phone,
          email,
          address: add_address,
          latitude: add_lat,
          longitude: add_lng,
          mapUrl,
          locationStatus,
          needCutlery,
          note,
          dispatchNote,
          itemsInfo,
          combosInfo,
          customItemsInfo,
          customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
          origin: salesType
        }
      : {
          orderTypeId,
          note,
          dispatchNote,
          itemsInfo,
          combosInfo,
          customItemsInfo,
          customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
        };

    POSTAPI_CSR_ADMIN("/order/admin/create", data).then((res) => {
      if (res.success) {
        POSTAPI_CSR_ADMIN("/order/status/change", {
          orderId: res.order._id,
          newStatus: ORDER_STATUS.QUEUE,
        }).then((res) => {
          if (res.success) {
            setFetchOrder(true);
            setCoordinates({ data: "" });
            setValues((x) => ({
              ...x,
              loading: false,
              orderId: "",
              itemsArr: [],
              name: "",
              phone: "",
              email: "",
              address: "",
              note: "",
              dispatchNote : '',
              needCutlery: false,
              itemModalVisible: false,
              spiceLevel: 28,
              item: null,
              thisItem: null,
              allItems: [],
              combo: null,
              allCombos: [],
              thisCombo: null,
              errorPosition: "",
              errorText: "",
              first: true,
              add_lat: "",
              add_lng: "",
              add_address: null,
              add_id: "",
              type: "",
              locationStatus: "",
              allModifiers: [],
              modifiersSel: [],
              quanSel: 1,
              add_item_loading: false,
              editItemModal: false,
              currentItem: null,
              customDiscount: { discountType: "percent", discountValue: null },
              customDeliveryCharge: null,
              subTotalPrice: null,
              thisCustomItem: null,
              mapUrl: null,
            }));
          } else if (res.error) {
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: res.error ?? res.message,
              loading: false,
            }));
          }
        });
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error ?? res.message,
          loading: false
        }));
      }
      closeModal();
    });
  }
  catch(e) {
    setValues((x) => ({
      ...x,
      errorPosition: "apiErr",
      errorText: e.error ?? e.message,
      loading: false,
    }));
  }
  };

  const handleCloseEditModal = () => {
    setShowEditModifiersModal(false);
    setValues((x) => ({
      ...x,
      currentItem: null,
      spiceLevel: null,
      modifiersSel: null,
    }));
  };

  const handleCloseDiscountModal = () => {
    setShowDiscountModal(false);
    setValues((x) => ({ ...x, currentItem: null }));
    setValues((x) => ({
      ...x,
      customDiscount: { discountType: "percent", discountValue: null },
      errorPosition: "",
      errorText: "",
    }));
  };

  useEffect(() => {
    if (coordinates?.data?.includes(",")) {
      let a = coordinates?.data?.split(",").map((item) => item.trim());
      setValues((x) => ({ ...x, add_lat: a[0], add_lng: a[1] }));
    } else {
      let b = coordinates?.data.split(" ").map((item) => item.trim());
      setValues((x) => ({ ...x, add_lat: b[0], add_lng: b[1] }));
    }
  }, [coordinates.data]);

  const form = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <div>
        <InputLabelCsr
          label="Name"
          varName={name}
          errorPosition={errorPosition}
          errorText={errorText}
        />
        <InputButtonCsr name="name" value={name} setValues={setValues} />
      </div>
      <div>
        <InputLabelCsr
          label="Phone"
          varName={phone}
          errorPosition={errorPosition}
          errorText={errorText}
        />
        <InputButtonCsr name="phone" value={phone} setValues={setValues} />
      </div>
      <div>
        <InputLabelCsr
          label="Email (optional)"
          varName={email}
          errorPosition={errorPosition}
          errorText={errorText}
        />
        <InputButtonCsr name="email" value={email} setValues={setValues} />
      </div>
      <div>
        <InputLabelCsr label="Address co-ordinates" varName={coordinates} />
        <InputButtonCsr
          name="data"
          value={coordinates.data}
          setValues={setCoordinates}
          placeholder="27.683206, 85.313344"
        />
      </div>
      <div>
        <InputLabelCsr label="Address URL" varName={coordinates} />
        <InputButtonCsr name="mapUrl" value={mapUrl} setValues={setValues} />
      </div>
      <div>
        <InputLabelCsr label="Location Confirmation" varName={locationStatus} />
        <div style={{ paddingLeft: "0.4rem" }}>
          <RadioGroup
            aria-label="location-status"
            name="location-status"
            value={locationStatus}
            onChange={(e) => {
              setValues((x) => ({ ...x, locationStatus: e.target.value }));
            }}
            row={true}
          >
            <CustomFormControlLabel
              value="Verified"
              control={<CustomRadio />}
              label="Verified"
            />

            <CustomFormControlLabel
              value="Hold"
              control={<CustomRadio />}
              label="Hold"
            />
            <CustomFormControlLabel
              value="Not Verified"
              control={<CustomRadio />}
              label="Not Verified"
            />
          </RadioGroup>
        </div>
      </div>

      <p style={{ fontSize: "1.5rem", fontWeight: "100" }} className="mt-2">
        Additional Order Details
      </p>
      <div className="d-flex align-content-center">
        <ForkKnife size={25} color="#8943EF" />
        <div className="ml-2 w-100">
          <div className="d-flex justify-content-between">
            <span style={{ fontFamily: "Blender Pro thin", fontWeight: "100" }}>
              Cutlery
            </span>
            <CsrSwitch
              checked={needCutlery}
              onChange={() =>
                setValues((x) => ({
                  ...x,
                  needCutlery: !needCutlery,
                }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const subTotalWithTax = useMemo(() => {
    return taxes.reduce((subtotal, item) => {
      if (item.type === "percent") {
        const taxAmount =
          (parseFloat(item.amount) / 100) * parseFloat(subTotalPrice);
        return parseFloat(subtotal + taxAmount);
      }
      if (item.type === "value") {
        const taxAmount = parseFloat(item.amount);
        return parseFloat(subtotal + taxAmount);
      }
      return subtotal;
    }, parseFloat(subTotalPrice));
  }, [subTotalPrice, taxes]);

  const addItemsByType = () => {
    switch (salesType) {
      case 'admin':
        return allItems.concat(allCombos)
      case 'day_sales':
        return dineInItems.concat(allCombos)
      case 'night_sales':
        return allItems.concat(allCombos)
      default:
        return []
    }
  };

  const AllItemSelectWithDetails = (e) => {
    const allItemsData = (salesType===ORDER_ORIGIN.DAY_SALES) ? dineInItems : allItems 
    var foundItem = allItemsData.find((x) => x._id === e.value);
    var foundCombo = allCombos.find((x) => x._id === e.value);
    if(foundItem) {
      setValues(x=>({...x, type: "Item"}))
      ItemSelectWithDetails(e)
    }
    else if(foundCombo) {
      setValues(x=>({...x, type: "Combo Item"}))
      ComboSelected(e)
    } 
    return
  };

  const addCustomItem = () => {
    setValues(x=>({...x, type: "Custom Item"}))
  }

  const AddCustomItemDiv = () => {
    return(
    <div className="d-flex flex-column mt-2">
      <InputLabelCsr
        label="Name"
        varName={name}
        errorPosition={errorPosition}
        errorText={errorText}
      />
      <TextField
        type="text"
        value={thisCustomItem?.name || ""}
        sx={{
          border: "1px solid #545356",
        }}
        className="custom-text-field"
        onChange={(e) => {
          setValues((x) => ({
            ...x,
            thisCustomItem: {
              ...x.thisCustomItem,
              name: e.target.value,
            },
          }));
        }}
        InputProps={{
          style: {
            borderRadius: "0px",
            outline: "none !important",
            color: "white",
            height: "2.5rem",
            fontFamily: "Blender Pro thin",
            "&:focus": {
              outline: "none !important",
            },
          },
        }}
      />
      <div className="mt-2" />
      <InputLabelCsr label="Price" />
      <TextField
        type="number"
        sx={{
          border: "1px solid #545356",
        }}
        value={thisCustomItem?.price || null}
        className="custom-text-field"
        onChange={(e) => {
          setValues((x) => ({
            ...x,
            thisCustomItem: {
              ...x.thisCustomItem,
              price: e.target.value,
            },
          }));
        }}
        InputProps={{
          style: {
            borderRadius: "0px",
            outline: "none !important",
            color: "white",
            height: "2.5rem",
            fontFamily: "Blender Pro thin",
            "&:focus": {
              outlineColor: "none !important",
            },
          },
        }}
      />
      <div className="mt-2" />
      {thisCustomItem && thisCustomItem.name && (
        <>
          <p className="csr_little_texts">Quantity</p>
          {CartQuantityCsr(quanSel, setValues)}
        </>
      )}
    </div>
    )
  }

  const AllItemsSelectDropdown = () => {
    return (
      <div>
      {type === "Custom Item" && AddCustomItemDiv()}
        {/* item */}
       {type !=="Custom Item" && <InputLabelCsr
          label="Item"
          varName={item}
          errorPosition={errorPosition}
          errorText={errorText}
        />}
        {type !=="Custom Item" && <Select
          autoFocus
          styles={customStyles}
          maxMenuHeight={"200px"}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          options={addItemsByType().map((item) =>
            item._id ? { label: item.name, value: item._id } : item,
          )}
          value={addItemsByType()
            .filter((option) => option._id === item)
            .map((item) => ({ label: item.name, value: item._id }))}
          name={"item"}
          onChange={(e) => AllItemSelectWithDetails(e)}
          className="mb-2"
        />}
        {type !=="Custom Item" &&  thisItem && (
          <>
            <p className="mt-3 mb-2 csr_little_texts">
              Price: Rs. {thisItem.price}
            </p>
            <p className="csr_little_texts">Quantity</p>
            {CartQuantityCsr(quanSel, setValues)}
          </>
        )}
        {type !=="Custom Item" && thisItem &&
          thisItem.modifiers &&
          thisItem.modifiers.length > 0 && (
            <>
              <FormGroup className="mt-3 csr_little_texts">
                {thisItem.modifiers.map((each, i) => (
                  <div key={i}>
                    <p
                      className="csr_little_texts"
                      style={{ color: "#a2a2a2" }}
                    >
                      Modifier {i + 1}
                    </p>
                    <div className="d-flex flex-column csr_little_texts_moderate csr_little_texts_moderate">
                      {each.options &&
                        each.options.map((eachOP, ind) => {
                          return (
                            <div
                              key={ind}
                              className="d-flex justify-content-between align-items-center csr_little_texts"
                            >
                              <CustomFormControlLabel
                                key={ind}
                                control={
                                  <CustomCheckbox
                                    // sx={{
                                    //   color: "#8943EF",
                                    //   "&.Mui-checked": {
                                    //     color: "#8943EF",
                                    //   },
                                    //   fontFamily: "Blender Pro thin",
                                    // }}
                                    // checked={checkedOrNot(ind, thisItem.modifiersArr_Selected[i])}
                                    value={each.sel}
                                    onClick={() =>
                                      modifierSelected(i, ind, thisItem)
                                    }
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={eachOP.name}
                              />
                              <span>Rs. {eachOP.price}</span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}
              </FormGroup>
            </>
          )}
        {/* spice level */}
        {type !=="Custom Item" && thisItem && thisItem.hasSpiceLevel && (
          <>
            {" "}
            <p className="spiceLevel_text_csr ml-auto mr-auto mt-4 text-center csr_little_texts">
              {getSpiceText(spiceLevel)}
            </p>
            <SliderMUI
              defaultValue={[20, 40]}
              value={spiceLevel}
              color="error"
              aria-label="modifier"
              step={null}
              onChange={handleChangeSlide}
              valueLabelDisplay="off"
              marks={[0, 28, 66, 100].map((each) => ({
                value: each,
                label: "",
              }))}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <ThemeProvider theme={csrTheme}>
        <div className="csr_create_order_header">
          <IconButton className="text-white ipadPort mr-3" onClick={e=>setView(false)}>
            <ArrowLeft />
          </IconButton>
          <span className="csr_create_order_header_titles">Create Order</span>
          <div style={{ paddingLeft: "0.4rem", marginRight:'auto', marginTop: 12 }}>
          <RadioGroup
            aria-label="location-status"
            name="location-status"
            style={{
              // backgroundColor:'#fff'
            }}
            value={salesType}
            onChange={(e) => {
              setValues((x) => ({ ...x, salesType: e.target.value, itemsArr:[] }));
            }}
            row={true}
          >
            <CustomFormControlLabel
              value={ORDER_ORIGIN.ADMIN}
              control={<CustomRadio />}
              label="Online Order"
              defaultChecked
            />

            <CustomFormControlLabel
              value={ORDER_ORIGIN.DAY_SALES}
              control={<CustomRadio />}
              label="Day Sales"
            />
            <CustomFormControlLabel
              value={ORDER_ORIGIN.NIGHT_SALES}
              control={<CustomRadio />}
              label="Night Sales"
            />
          </RadioGroup>
        </div>
          <select
            name="orderTypeId"
            id="orderTypeId"
            className="select"
            value={orderTypeId}
            onChange={(e) =>
              setValues((x) => ({
                ...x,
                orderTypeId: e.target.value,
              }))
            }
          >
            <option className="select_option" disabled value="">
              Select an option
            </option>

            {deliveryPartners.map(({ _id, name }) => {
              return (
                <option key={_id} value={_id} className="select_option">
                  {name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="csr_create_div">
          <div className="adoc_left">
            <p style={{ fontSize: "1.5rem" }}>
              {orderTypeId && selectedPartner ? "Customer" : "Order"} Details
            </p>

            <p className="s2 w3">
              {orderTypeId === ""
                ? form
                : orderTypeId.length > 0 && selectedPartner && form}
            </p>
            <InputLabelCsr label="Note" varName={note} />
            <InputCompTextAreaCsr
              name="note"
              value={note}
              setValues={setValues}
              className="csr_text_area"
            />
            <InputLabelCsr label="Dispatch Note" varName={dispatchNote} />
            <InputCompTextAreaCsr
              name="dispatchNote"
              value={dispatchNote}
              setValues={setValues}
              className="csr_text_area"
            />
          </div>

          <div className="adoc_left">
            {itemsArr && itemsArr.length > 0 && (
              <div className="adocr_right_items_div_csr">
                {itemsArr.map((prod, ind) => {
                  let totalCostModifiers = 0;

                  if (prod?.modifiers) {
                    for (const modifier of prod.modifiers) {
                      for (const option of modifier.options) {
                        if (option.sel) {
                          totalCostModifiers += option.price;
                        } else {
                          totalCostModifiers += 0;
                        }
                      }
                    }
                  }

                  const percentPrice =
                    prod.discount && prod.discount.value
                      ? Math.ceil(parseFloat(prod.price)) -
                        (Math.ceil(parseFloat(prod.discount.value)) / 100) *
                          Math.ceil(parseFloat(prod.price))
                      : 0;
                  const percentPriceWithModifiers =
                    percentPrice +
                    (prod?.modifiers?.length > 0
                      ? Math.ceil(parseFloat(totalCostModifiers))
                      : 0);
                  const valuePrice = prod?.discount?.value
                    ? Math.ceil(parseFloat(prod?.price)) -
                      Math.ceil(parseFloat(prod?.discount?.value))
                    : 0;
                  const valuePriceWithModifiers =
                    valuePrice +
                    (prod?.modifiers?.length > 0
                      ? Math.ceil(parseFloat(totalCostModifiers))
                      : 0);

                  return (
                    <div className="eachCart_div" key={ind}>
                      <div className="mb-2 d-flex" key={ind}>
                        <div className="d-flex flex-column w-100 justify-content-center">
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="csr_little_texts_moderate">
                              {prod.name}
                            </span>
                            {AdOrderEditDeleteMenuCsr(
                              prod,
                              editItemFn,
                              add_edit_visible_ind,
                              Set_add_edit_visible,
                              ind,
                              itemsArr,
                              setValues,
                              setShowDiscountModal,
                              setShowEditModifiersModal,
                            )}
                          </div>
                          <div className="d-flex w-100 justify-content-between mt-2">
                            {/* <span>Qty : {prod.quantity}</span> */}
                            {CartQuantityAdminArrCsr(prod, itemsArr, setValues)}
                            <span className="eachCart_price_csr csr_little_texts_moderate">
                              {prod.discount &&
                              prod.discount.type === "percent" &&
                              prod.discount.value !== null &&
                              prod.discount.value > 0 ? (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      Math.ceil(
                                        parseFloat(percentPriceWithModifiers),
                                      ) * Math.ceil(parseFloat(prod.quantity)),
                                    ),
                                  )}
                                  <del
                                    className="ml-1 csr_little_texts_moderate"
                                    style={{ color: "#fff" }}
                                  >
                                    Rs.{" "}
                                    {Math.ceil(
                                      parseFloat(
                                        (Math.ceil(parseFloat(prod.price)) +
                                          Math.ceil(
                                            parseFloat(totalCostModifiers),
                                          )) *
                                          Math.ceil(parseFloat(prod.quantity)),
                                      ),
                                    )}
                                  </del>
                                </>
                              ) : prod.discount &&
                                prod.discount.type === "value" &&
                                prod.discount.value !== null &&
                                prod.discount.value > 0 ? (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      Math.ceil(
                                        parseFloat(valuePriceWithModifiers),
                                      ) * parseFloat(prod.quantity),
                                    ),
                                  )}
                                  <del
                                    className="ml-1 csr_little_texts_moderate"
                                    style={{ color: "#fff" }}
                                  >
                                    Rs.{" "}
                                    {Math.ceil(
                                      parseFloat(
                                        (Math.ceil(parseFloat(prod.price)) +
                                          Math.ceil(
                                            parseFloat(totalCostModifiers),
                                          )) *
                                          prod.quantity,
                                      ),
                                    )}
                                  </del>
                                </>
                              ) : (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      (Math.ceil(parseFloat(prod.price)) +
                                        Math.ceil(
                                          parseFloat(totalCostModifiers),
                                        )) *
                                        prod.quantity,
                                    ),
                                  )}{" "}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* customizations */}
                      {((prod.modifiers &&
                        prod.modifiers
                          .map((item) => ({
                            ...item,
                            options: item.options.filter(
                              (option) => option.sel,
                            ),
                          }))
                          .filter((item) => item.options.length > 0).length >
                          0) ||
                        prod.hasSpiceLevel === true) && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <CaretDown weight="bold" className="ml-2" />
                            }
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            style={{
                              color: "#a2a2a2",
                              fontSize: "1rem",
                              fontWeight: "400",
                            }}
                          >
                            Customizations
                          </AccordionSummary>
                          <AccordionDetails>
                            {prod.modifiers && prod.modifiers.length > 0 && (
                              <>
                                {prod.modifiers
                                  .map((item) => ({
                                    ...item,
                                    options: item.options.filter(
                                      (option) => option.sel,
                                    ),
                                  }))
                                  .filter((item) => item.options.length > 0)
                                  .map((each, i) => (
                                    <div key={i}>
                                      <p
                                        style={{ color: "#a2a2a2" }}
                                        className="csr_little_texts"
                                      >
                                        Modifier {i + 1}
                                      </p>
                                      <div className="d-flex flex-column mt-2 csr_little_texts_moderate">
                                        {each.options &&
                                          each.options.map((eachOP, ind) => {
                                            return (
                                              <div
                                                key={ind}
                                                className="
                                                                  d-flex justify-content-between
                                                                   align-items-center mb-2"
                                              >
                                                <span>{eachOP.name}</span>
                                                <span>Rs. {eachOP.price}</span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  ))}
                              </>
                            )}
                            {prod.hasSpiceLevel && prod.spiceLevel !== null && (
                              <div className={"d-flex flex-column"}>
                                <span
                                  className="mb-1 "
                                  style={{
                                    color: "#a2a2a2 !important",
                                    fontSize: "0.9rem",
                                    fontWeight: "400",
                                    fontFamily: "Blender Pro thin",
                                  }}
                                >
                                  Spice Level
                                </span>
                                <span className="csr_little_texts_moderate">
                                  {prod.spiceLevel}
                                </span>
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  );
                })}

                <div className="mt-4 mb-1 border-top" />
                <div className="d-flex flex-column pl-3 pr-3 w-100 justify-content-between align-items-center mt-4">
                  <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                    <div>Subtotal</div>
                    <div className="fs-6 fw-semibold ">
                      Rs. {Math.ceil(parseFloat(subTotalPrice))}
                    </div>
                  </div>
                  {taxes &&
                    taxes.length > 0 &&
                    taxes.map((item) => {
                      return (
                        <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                          <div>{item.name}</div>
                          <div className="fs-6 fw-semibold ">
                            {item.type === "value" && `Rs. ${item.amount}`}
                            {item.type === "percent" &&
                              `Rs. ${parseFloat(
                                parseFloat(
                                  (parseFloat(item.amount) / 100) *
                                    subTotalPrice,
                                ).toFixed(2),
                              )} (${item.amount} %)`}
                          </div>
                        </div>
                      );
                    })}
                  {selectedPartner ? (
                    <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                      <div>Delivery charge</div>
                      <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                        <div className="mr-2">Rs.{"   "}</div>
                        <TextField
                          type="number"
                          sx={{
                            border: "1px solid #545356",
                          }}
                          placeholder="Amount..."
                          value={customDeliveryCharge}
                          onChange={(e) => {
                            setValues((x) => ({
                              ...x,
                              customDeliveryCharge: e.target.value,
                            }));
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "0px",
                              width: "90px",
                              height: "30px",
                              color: "white",
                              fontFamily: "Blender Pro thin",
                              "&:focus": {
                                outline: "none !important",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    className="d-flex  flex-row w-100 mt-3 justify-content-between align-items-center"
                    style={{ fontFamily: "Blender Pro thin" }}
                  >
                    <div>Total</div>
                    <div className="fs-3 fw-bold ">
                      Rs.{" "}
                      {taxes && taxes.length > 0
                        ? customDeliveryCharge > 0 &&
                          customDeliveryCharge !== null
                          ? parseFloat(
                              parseFloat(subTotalWithTax) +
                                parseFloat(customDeliveryCharge),
                            ).toFixed(2)
                          : parseFloat(subTotalWithTax).toFixed(2)
                        : customDeliveryCharge > 0
                          ? parseFloat(
                              parseFloat(subTotalPrice) +
                                parseFloat(customDeliveryCharge),
                            ).toFixed(2)
                          : parseFloat(subTotalPrice).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Button
              className="csr_add_item mt-3"
              onClick={() => handleAddItemBtn()}
            >
              <Plus color="#FCEE0A" size={15} className="mr-1" />
              Add Item
            </Button>

            {itemsArr && itemsArr.length > 0 && (
              <div className="mt-auto">
                {loading ? (
                  <>
                    <div className="half_right_btn_comp_csr">
                      <CircularProgress style={{ color: "#00F0FF" }} />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="half_right_btn_comp_csr"
                      onClick={() => createFn()}
                    >
                      {" "}
                      {SvgButtonCSR({text:'ADD ITEMS'})}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <CsrModal
          open={itemModalVisible}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* top */}
            <div className="d-flex justify-content-between align-items-center mb-1">
              <span className="csr_create_order_header_titles">Add Item</span>
              {
              type==="Custom Item" ?
              <div className="ml-auto" style={{
                border:'1px solid #00f0ff',
                color:'#00f0ff',
                padding:'2px 4px',
                borderRadius:'5px',
                cursor:'pointer'
              }} onClick={() => setValues(x=>({...x, type: ""}))}>
                Remove Custom X
              </div>
            :
              <div className="ml-auto" style={{
                border:'1px solid #00f0ff',
                color:'#00f0ff',
                padding:'2px 4px',
                borderRadius:'5px',
                cursor:'pointer'
              }} onClick={() => addCustomItem()}>
                + Custom Item
              </div>
            }
              <IconButton onClick={() => closeModal()}>
                <X color="#FFFFFF" />
              </IconButton>
            </div>
            {/* all items new */}
            {AllItemsSelectDropdown()}
            {/* end */}
            <div className="d-flex justify-content-end mt-4 w-100 gap-4 ">
              <Button className="adocr_cancel_csr" onClick={() => closeModal()}>
                CANCEL
              </Button>

              {add_item_loading ? (
                <div className="adocr_add_btn_csr">
                  <CircularProgress style={{ color: "#00F0FF" }} />
                </div>
              ) : (
                <div className="adocr_add_btn_csr" onClick={() => addItem()}>
                  {" "}
                  {SvgButtonCSR({text:'ADD ITEM'})}
                </div>
              )}
            </div>
          </Box>
        </CsrModal>

        {/* Discount Item Modal */}
        <CsrModal
          open={showDiscountModal}
          onClose={() => handleCloseDiscountModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center">
              <span className="csr_create_order_header_titles">
                Add Discount
              </span>
              <IconButton onClick={() => handleCloseDiscountModal()}>
                <X color="#FFFFFF" />
              </IconButton>
            </div>

            <FormGroup className="mt-3">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="discount-type"
                  name="discount-type"
                  value={customDiscount.discountType}
                  onChange={handleDiscountTypeChange}
                >
                  <CustomFormControlLabel
                    value="percent"
                    control={<CustomRadio />}
                    label="Percent"
                  />
                  <CustomFormControlLabel
                    value="value"
                    control={<CustomRadio />}
                    label="Value"
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                type="number"
                sx={{
                  border: "1px solid #545356",
                  marginTop: "10px",
                }}
                value={customDiscount.discountValue}
                onChange={handleDiscountValueChange}
                InputProps={{
                  endAdornment:
                    customDiscount.discountType === "percent" ? "%" : "Rs",
                  style: {
                    borderRadius: "0px",
                    borderColor: "#545356",
                    fontFamily: "Blender Pro thin",

                    color: "white",
                    "&:focus": {
                      outline: "0",
                    },
                  },
                }}
              />
            </FormGroup>

            <div className="d-flex justify-content-end mt-4 gap-4">
              <Button
                className="adocr_cancel_csr"
                onClick={() => handleCloseDiscountModal()}
              >
                {" "}
                Cancel
              </Button>

              <div
                className="adocr_add_btn_csr"
                onClick={() => applyDiscount()}
              >
                {" "}
                {SvgButtonCSR({text:'APPLY DISCOUNT'})}
              </div>
            </div>
          </Box>
        </CsrModal>

        {/* Edit Item Modal */}
        <CsrModal
          open={showEditModifiersModal}
          onClose={() => handleCloseEditModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* top */}
            <div className="d-flex justify-content-between align-items-center">
              <span className="csr_create_order_header_titles">
                Edit Customizations
              </span>
              <IconButton onClick={() => handleCloseEditModal()}>
                <X color="#FFFFFF" />
              </IconButton>
            </div>

            {currentItem &&
              currentItem?.modifiers &&
              currentItem?.modifiers?.length > 0 && (
                <>
                  <FormGroup className="mt-3">
                    {currentItem &&
                      currentItem.modifiers.map((each, i) => {
                        return (
                          <div key={i}>
                            <p
                              className="csr_little_texts"
                              style={{ color: "#a2a2a2" }}
                            >
                              Modifier {i + 1}
                            </p>
                            <div className="d-flex flex-column">
                              {each.options &&
                                each.options.map((item, ind) => {
                                  return (
                                    <div
                                      key={ind}
                                      className="d-flex justify-content-between align-items-center csr_little_texts"
                                    >
                                      <CustomFormControlLabel
                                        key={ind}
                                        control={
                                          <CustomCheckbox
                                            // sx={{
                                            //   color: "#8943EF",
                                            //   "&.Mui-checked": {
                                            //     color: "#8943EF",
                                            //   },
                                            // }}
                                            defaultChecked={item.sel}
                                            value={item.sel}
                                            onChange={() =>
                                              modifierSelectedEdit(
                                                i,
                                                ind,
                                                currentItem,
                                              )
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                        label={item.name}
                                      />
                                      <span className="csr_little_texts">
                                        Rs. {item.price}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </FormGroup>
                </>
              )}

            {/* spice level */}
            {currentItem &&
              currentItem.hasSpiceLevel === true &&
              !isTextEmpty(currentItem.spiceLevel) && (
                <>
                  {" "}
                  <p className="spiceLevel_text_csr ml-auto mr-auto mt-4 text-center csr_little_texts">
                    {getSpiceText(spiceLevel)}
                  </p>
                  <SliderMUI
                    defaultValue={[20, 40]}
                    value={spiceLevel}
                    color="error"
                    aria-label="modifier"
                    step={null}
                    onChange={handleChangeSlide}
                    valueLabelDisplay="off"
                    marks={[0, 28, 66, 100].map((each) => ({
                      value: each,
                      label: "",
                    }))}
                  />
                </>
              )}

            {/* end */}
            <div className="d-flex justify-content-end mt-3 gap-4">
              <Button
                className="adocr_cancel_csr"
                onClick={() => handleCloseEditModal()}
              >
                {" "}
                Cancel
              </Button>

              <div className="adocr_add_btn_csr" onClick={() => updateFnAPI()}>
                {" "}
                {SvgButtonCSR({text:'UPDATE'})}
              </div>
            </div>
          </Box>
        </CsrModal>
      </ThemeProvider>

      {AlertErrorCsr(errorPosition, errorText, setValues)}
    </>
  );
};

export const Table = ({
  fetchOrder,
  setFetchOrder,
  setShowCreateOrder,
  setShowViewOrder,
  setShowEditOrder,
  setCurrentId,
  fetchDraft,
  setFetchDraft,
  setView
}) => {
  const [audio] = useState(new Audio(ting));
  const [unread, setUnread] = useState(0);
  const [values, setValues] = useState({
    orders: [],
    first: true,
    loading: true,
    edAnchor: null,
    edClicked: null,
    errorPosition: "",
    errorText: "",
    pageSize: 10,
    searchFilter: "",
    vendors: [],
    vendorsMenuArrBool: [],
    vendorsSend : [],
    vendorsArr: [],
    trackVendors: false,
  });

  const {
    orders,
    loading,
    pageSize,
    searchFilter,
    vendorsSend,
    vendors,
    vendorsMenuArrBool,
    vendorsArr,
    trackVendors,
  } = values;

  const filteredOrders = useMemo(() => {
    if (!searchFilter) return orders;
    return orders.filter((item) =>
      item.trackingNumber.startsWith(parseInt(searchFilter)),
    );
  }, [orders, searchFilter]);

  const handleRowClick = (item) => {
    if (window.matchMedia("(min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait)").matches) {
      setView(true)
      setShowViewOrder(true);
      setCurrentId(item._id);
      return
    }
    if (item && item._id) {
      setCurrentId(item._id);
      setShowViewOrder(true);
      setShowEditOrder(false);
      setShowCreateOrder(false);
    }
  };

  const fetch = useCallback(() => {
    if (vendors.length === 0 || vendorsSend.length===0) return;
    POSTAPI_CSR_ADMIN("/order/", {
      statuses : vendors,
      apps : vendorsSend,
      isCSR: true,
    }).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          orders: res.orders,
          loading: false,
        }));
        setFetchOrder(false);
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          errorText: res.error,
          loading: false,
        }));
      setFetchOrder(false);
    });
  }, [setFetchOrder, vendors, vendorsSend]);

  useEffect(() => {
    fetch();
  }, [vendors, fetch]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [fetch]);

  useEffect(() => {
    if (fetchOrder) {
      fetch();
    }
  }, [fetchOrder, fetch]);

  useEffect(() => {
    GETAPI_OrderTypesCsr(`/order/orderType`).then((res) => {
      if (res.success) {
        setValues((prev) => ({
          ...prev,
          vendorsSend: res.orderTypes
            .map((orderType) => orderType.name)
            .concat("Online"),
          first: true,
        }));
      }
    });
    const filterArray = [ORDER_STATUS.DRAFT, ORDER_STATUS.QUEUE, ORDER_STATUS.COOKING, ORDER_STATUS.NOT_ASSIGNED,
      ORDER_STATUS.PENDING, ORDER_STATUS.PICKED_UP, ORDER_STATUS.CANCELED, ORDER_STATUS.COMPLETED
    ]
    setValues((prev) => ({
      ...prev,
      vendors: filterArray,
      first: true,
    }));
  }, []);

  useEffect(() => {
    if (!trackVendors && vendors.length > 0) {
      const allStatusTypes = [ORDER_STATUS.DRAFT, ORDER_STATUS.QUEUE, ORDER_STATUS.COOKING, ORDER_STATUS.FAILED, ORDER_STATUS.NOT_ASSIGNED,
        ORDER_STATUS.PENDING, ORDER_STATUS.PICKED_UP, ORDER_STATUS.CANCELED, ORDER_STATUS.COMPLETED
      ]
      setValues((prev) => ({
        ...prev,
        vendorsArr: allStatusTypes,
        vendorsMenuArrBool: allStatusTypes.map((orderType) => {
          return orderType === 'Failed' ? false : true
        }),
        trackVendors: true,
      }));
    }
  }, [vendors, trackVendors, vendorsArr]);

  const fetchDraftItems = useCallback(() => {
    POSTAPI_CSR_ADMIN("/order/countDraftOrders").then((res) => {
      if (res.success) {
        setUnread(res.countDraftOrders);
        if (res.countDraftOrders > 0) {
          var resp = audio.play();
          if (resp !== undefined) {
            resp.then((_) => {}).catch((error) => {});
          }
        }
        setFetchDraft(false);
      } else if (res) console.log(res.error);
    });
  }, [audio, setFetchDraft]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchDraftItems();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [audio, unread, fetchDraftItems]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (unread > 0) {
        var resp = audio.play();
        if (resp !== undefined) {
          resp
            .then((_) => {
              // autoplay starts!
            })
            .catch((error) => {
              //show error
            });
        }
      }
    }, 1500);

    return () => clearInterval(intervalId);
  }, [audio, unread]);

  useEffect(() => {
    fetchDraftItems();
  }, [fetchDraftItems]);

  useEffect(() => {
    if (fetchDraft) {
      fetchDraftItems();
    }
  }, [fetchDraft, fetchDraftItems]);

  const MUIMenuVendors = ({ vendors, setValues }) => {
    const [checked, setChecked] = useState(vendorsMenuArrBool);
    // const [apps, setApps] = useState(vendors);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
      var send_arr = [];
      checked.map((eachBool, i) => {
        if (eachBool) {
          send_arr.push(vendorsArr[i]);
          return eachBool;
        }
        return eachBool;
      });
      // setApps(send_arr);
      setValues((prev) => ({
        ...prev,
        vendors: send_arr,
        vendorsMenuArrBool: checked,
        first: true,
      }));
    };
    const handleChangeAll = (event) => {
      var checkedNow = [...checked];
      checkedNow.fill(event.target.checked);
      setChecked(checkedNow);
    };
    const handleChange = (event, i) => {
      var checkedNow = [...checked];
      checkedNow[i] = event.target.checked;
      setChecked(checkedNow);
    };
    return (
      <div className="ml-auto">
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="ad_or_top_btn ml-auto filter_button_csr"
        >
          <FadersHorizontal />
          <div>Filter</div>
        </Button>
        <MUIMenu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            "& .MuiMenu-paper": {
              backgroundColor: "black",
              border: "1px solid #545356",
              borderRadius: "0px",
            },
          }}
        >
          <MenuItem
            className="d-flex flex-column"
            sx={{
              backgroundColor: "black !important",
            }}
          >
            <CustomFormControlLabel
              label="All"
              className="mr-auto mb-2"
              control={
                <CustomCheckbox
                  checked={checked?.every((v) => v === true)}
                  onChange={handleChangeAll}
                />
              }
            />
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              {vendorsArr.map((each, i) => (
                <CustomFormControlLabel
                  key={i}
                  className="mb-0"
                  label={each}
                  control={
                    <CustomCheckbox
                      checked={checked[i]}
                      onChange={(e) => handleChange(e, i)}
                    />
                  }
                />
              ))}
            </Box>
          </MenuItem>
        </MUIMenu>
      </div>
    );
  };

  const columnsData = [
    {
      field: "priorityLevel",
      headerName: "",
      width: 24,
      renderCell: (params) => {
        return (
          <div className="">
              {getPriorityLevelIconCSR(params.value)}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "",
      width: 55,
      renderCell: (params) => {
        return (
          <div className="crs_table_rows" style={{ justifyContent: "center" }}>
            <div style={{ width: "1.5rem", height: "1.59rem" }}>
              {ShowOriginLogo(params?.row?.origin)}
            </div>
          </div>
        );
      },
    },
    {
      field: "trackingNumber",
      headerName: "Order ID",
      width: 100,
      renderCell: (params) => {
        return (
          <span className="crs_table_rows" style={{ color: "#fff" }}>
            #ORD-{params.value}
          </span>
        );
      },
    },
    {
      field: "payment",
      headerName: "Amount",
      width: 100,
      renderCell: (params) => {
        return (
          <span className="crs_table_rows" style={{ color: "#fff" }}>
            {params.value ? "Rs. " + parseInt(params.value.total) : ""}
            {params.row?.delivery?.riderPaidStatus === "Hold" ? (
              <>
                <WarningCircle
                  style={{ color: "#cdd417" }}
                  size={20}
                  className="ml-1"
                />
              </>
            ) : (
              ""
            )}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 110,
      renderCell: (params) => {
        return (
          <div className="crs_table_rows">
            <Button
              style={{ width: "110px", paddingInline: "10px", outline: "0" }}
              className={statusButtonClass(params.value)[0]}
            >
              {statusButtonClass(params.value)[1]}
            </Button>
          </div>
        );
      },
    },
    {
      field: ".",
      headerName: "",
      width: 24,
      renderCell: (params) => {
        return (
          <div
            className="crs_table_rows d-flex justify-content-center w-100 "
            style={{ width: "1.5rem", height: "1.59rem" }}
          >
            <IconButton
              onClick={(e) => copyUserDetails(e, params.row, setValues)}
              sx={{ color: "#FFF" }}
            >
              <Copy sx={{ color: "#FFF" }} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return (
    <div className="csr_dashboard_table">
      <div className="search">
        <InputButtonSearchCsr
          name="searchFilter"
          value={searchFilter}
          setValues={setValues}
        />
        <MUIMenuVendors vendors={vendors} setValues={setValues} />
        <div
          className="add"
          onClick={() => {
            if((window.matchMedia("(min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait)").matches)            )
            setView(true)
              setShowCreateOrder(true);
            setShowEditOrder(false);
            setShowViewOrder(false);
            setCurrentId("");
          }}
        >
          <Plus size={30} />
        </div>
      </div>
      <div className="table">
        <DataGridNovaCsr
          loading={loading}
          getRowHeight={() => "auto"}
          rows={filteredOrders}
          columns={columnsData}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 25, 50, 100]}
          onPageSizeChange={(newPageSize) =>
            setValues((x) => ({ ...x, pageSize: newPageSize }))
          }
          getRowId={(row) => row._id}
          disableSelectionOnClick
          disableColumnMenu
          headerHeight={54}
          footerHeight={54}
          height={568}
          width={476}
          onCellClick={(e) => {
            handleRowClick(e.row);
          }}
          hideFooterSelectedRowCount={true}
          // checkboxSelection={true}
          // slots={{
          //   footer: () => <CustomFooterStatusComponent status={3} />,
          // }}
        />
      </div>
      ;
    </div>
  );
};

// function CustomFooterStatusComponent({ count }) {
//   return (
//     <Box sx={{ p: 1, display: 'flex', marginLeft: 2 }}>
//       {count}
//     </Box>
//   );
// }

export const EditOrder = ({
  currentId,
  setCurrentId,
  setShowCreateOrder,
  setFetchOrder,
  setShowEditOrder,
  refetchEdit,
  setRefetchEdit,
  setShowViewOrder,
  setView
}) => {
  let id = currentId;
  let editing = true;
  let [orderData, setOrderData] = useState({});
  let [promoAmount, setPromoAmount] = useState(0);
  const salesType = orderData?.origin ?? ORDER_ORIGIN.ADMIN

  const filteredArray = useMemo(() => {
    if (orderData && orderData?.foods?.length > 0) {
      return filterAndModifyFoods(orderData.foods);
    }
    return [];
  }, [orderData]);

  useEffect(() => {
    if (
      !orderData.isAdminCreated &&
      orderData?.payment?.hasOwnProperty("promoDiscount") &&
      orderData?.payment?.promoDiscount
    ) {
      setPromoAmount(orderData?.payment?.promoDiscount);
    } else {
      setPromoAmount(0);
    }
  }, [orderData]);

  const [values, setValues] = useState({
    itemsArr: filteredArray,
    orderId: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    orderTypeId: "",
    paidAmount: 0,
    deliveryPartners: [],
    note: "",
    dispatchNote : '',
    needCutlery: false,
    itemModalVisible: false,
    spiceLevel: 28,
    item: null,
    thisItem: null,
    allItems: [],
    dineInItems : [],
    combo: null,
    allCombos: [],
    thisCombo: null,
    errorPosition: "",
    errorText: "",
    first: true,
    loading: false,
    // mapCenter: [27.7172, 85.3240],
    add_lat: "",
    add_lng: "",
    add_address: null,
    add_id: "",
    // modal
    type: "",
    locationStatus: "",
    allModifiers: [],
    modifiersSel: [],
    quanSel: 1,
    add_item_loading: false,
    editItemModal: false,
    currentItem: null,
    customDiscount: { discountType: "percent", discountValue: null },
    customDeliveryCharge: null,
    subTotalPrice: null,
    thisCustomItem: null,
    mapUrl: null,
    taxes: [],
    data_loading: true,
  });
  const {
    itemsArr,
    orderId,
    name,
    phone,
    email,
    orderTypeId,
    paidAmount,
    deliveryPartners,
    locationStatus,
    note,
    dispatchNote,
    needCutlery,
    itemModalVisible,
    spiceLevel,
    item,
    thisItem,
    allItems,
    dineInItems,
    currentItem,
    allCombos,
    thisCombo,
    errorPosition,
    errorText,
    first,
    thisCustomItem,
    // mapCenter,
    add_lat,
    add_lng,
    add_address,
    //  add_id,
    type,
    // allModifiers,
    modifiersSel,
    quanSel,
    add_item_loading,
    loading,
    customDiscount,
    customDeliveryCharge,
    subTotalPrice,
    mapUrl,
    taxes,
    data_loading,
  } = values;
  const [add_edit_visible_ind, Set_add_edit_visible] = useState(null);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [showEditModifiersModal, setShowEditModifiersModal] = useState(false);
  const [coordinates, setCoordinates] = useState({ data: "" });

  useEffect(() => {
    setValues((x) => ({
      ...x,
      itemsArr: filteredArray,
    }));
  }, [filteredArray]);

  useEffect(() => {
    if (coordinates?.data?.includes(",")) {
      let a = coordinates?.data?.split(",").map((item) => item.trim());
      setValues((x) => ({ ...x, add_lat: a[0], add_lng: a[1] }));
    } else {
      let b = coordinates?.data.split(" ").map((item) => item.trim());
      setValues((x) => ({ ...x, add_lat: b[0], add_lng: b[1] }));
    }
  }, [coordinates.data]);

  useEffect(() => {
    if (orderData && Object.keys(orderData).length > 0) {
      setValues((x) => ({
        ...x,
        orderId: orderData._id,
        name: orderData?.delivery?.location?.name || "",
        phone: orderData?.delivery?.location?.phone || "",
        email:
          orderData.delivery && orderData.delivery.location
            ? orderData.delivery.location.email
            : "",
        address: orderData?.delivery?.location?.address || "",
        orderTypeId: orderData?.delivery?.deliveryPartner?._id || "",
        note: orderData?.note || "",
        dispatchNote: orderData?.dispatchNote || "",
        needCutlery: orderData?.needCutlery ? orderData?.needCutlery : false,
        mapUrl: orderData?.delivery?.location?.mapUrl || "",
        add_lat: orderData?.delivery?.location?.latitude || "",
        add_lng: orderData?.delivery?.location?.longitude || "",
        add_address: orderData?.delivery?.location?.address || "",
        locationStatus: orderData?.locationStatus || "",
        customDeliveryCharge: orderData.isAdminCreated
          ? orderData?.payment?.deliveryCharge || null
          : orderData?.payment?.deliveryCharge || null,
        paidAmount: orderData?.payment?.paidAmount
          ? orderData?.payment?.paidAmount
          : 0,
      }));
    }

    if (
      orderData?.delivery?.location?.latitude &&
      orderData?.delivery?.location?.longitude
    ) {
      setCoordinates((x) => ({
        ...x,
        data: `${orderData?.delivery?.location?.latitude} ${orderData?.delivery?.location?.longitude}`,
      }));
    } else {
      setCoordinates((x) => ({
        ...x,
        data: "",
      }));
    }
  }, [orderData, filteredArray]);

  //compares with all modifiers and creates and array with a property of selected or not
  function filterAndModifyFoods(foods) {
    return foods.map((food) => {
      if (food.customId) {
        return {
          ...food,
          spiceLevel: "Low",
          hasSpiceLevel: false,
          modifiers: [],
        };
      } else {
        // If customId doesn't exist
        if (
          !food.modifiers ||
          !food.allModifiers ||
          food.modifiers.length === 0 ||
          food.allModifiers.length === 0
        ) {
          return food;
        } else {
          const allModifiersMap = food.allModifiers.reduce((map, modifier) => {
            map[modifier.modifierId] = modifier;
            return map;
          }, {});
          const allModifiersFiltered = Object.values(allModifiersMap);

          const updatedItems = allModifiersFiltered.map((item) => {
            const updatedOptions = item.options.map((option) => ({
              ...option,
              sel: food.modifiers.some(
                (modifier) =>
                  modifier.modifierId === item.modifierId &&
                  modifier.name === item.name &&
                  modifier.options.some(
                    (modifierOption) =>
                      modifierOption.name === option.name &&
                      modifierOption.price === option.price,
                  ),
              ),
            }));

            return {
              ...item,
              options: updatedOptions,
            };
          });

          // Create the final modified food object
          return {
            ...food,
            modifiers: updatedItems,
          };
        }
      }
    });
  }

  useEffect(() => {
    if (!currentId) {
      setShowCreateOrder(true);
      setShowEditOrder(false);
      setCurrentId("");
    }
  }, [currentId, setShowCreateOrder, setShowEditOrder, setCurrentId]);

  useEffect(() => {
    setValues((x) => ({
      ...x,
      data_loading: true,
      first: true,
    }));
  }, [currentId]);

  useEffect(() => {
    if (refetchEdit) {
      setValues((x) => ({
        ...x,
        data_loading: true,
        first: true,
      }));
    }
  }, [refetchEdit]);

  useEffect(() => {
    POSTAPI_CSR_ADMIN("/order/adminOne/", { orderId: id }).then((res) => {
      if (res.success) {
        setOrderData(res.order);
        setValues((x) => ({
          ...x,
          data_loading: false,
          changeState_loading: false,
        }));
        setRefetchEdit && setRefetchEdit(false);
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          data_loading: false,
        }));
      setRefetchEdit && setRefetchEdit(false);
    });
  }, [id, currentId, setRefetchEdit]);

  const calculateTotalItemPrices = useMemo(() => {
    return () => {
      let totalItemPrices = 0;

      itemsArr.forEach((prod) => {
        let totalCostModifiers = 0;

        if (prod.modifiers) {
          for (const modifier of prod.modifiers) {
            for (const option of modifier.options) {
              if (option.sel) {
                totalCostModifiers += option.price;
              } else {
                totalCostModifiers += 0;
              }
            }
          }
        }

        const percentPrice =
          prod.discount && prod.discount.value
            ? Math.ceil(
                parseFloat(
                  Math.ceil(parseFloat(prod?.actualPrice)) -
                    (prod?.discount?.value / 100) *
                      Math.ceil(parseFloat(prod?.actualPrice)),
                ),
              )
            : 0;
        const percentPriceWithModifiers =
          percentPrice +
          (prod?.modifiers?.length > 0
            ? Math.ceil(parseFloat(totalCostModifiers))
            : 0);
        const valuePrice =
          prod.discount && prod.discount.value
            ? Math.ceil(
                parseFloat(
                  Math.ceil(parseFloat(prod?.actualPrice)) -
                    Math.ceil(parseFloat(prod.discount?.value)),
                ),
              )
            : 0;
        const valuePriceWithModifiers =
          valuePrice +
          (prod?.modifiers?.length > 0
            ? Math.ceil(parseFloat(totalCostModifiers))
            : 0);

        if (
          prod.discount &&
          prod.discount.type === "percent" &&
          prod.discount.value !== null &&
          prod.discount.value > 0
        ) {
          totalItemPrices += Math.ceil(
            parseFloat(
              Math.ceil(parseFloat(percentPriceWithModifiers)) *
                parseFloat(prod.quantity),
            ),
          );
        } else if (
          prod.discount &&
          prod.discount.type === "value" &&
          prod.discount.value !== null &&
          prod.discount.value > 0
        ) {
          totalItemPrices += Math.ceil(
            parseFloat(
              Math.ceil(parseFloat(valuePriceWithModifiers)) * prod.quantity,
            ),
          );
        } else {
          totalItemPrices += Math.ceil(
            parseFloat(
              (Math.ceil(parseFloat(prod.actualPrice)) +
                Math.ceil(parseFloat(totalCostModifiers))) *
                prod.quantity,
            ),
          );
        }
      });

      setValues((x) => ({
        ...x,
        subTotalPrice: Math.ceil(parseFloat(totalItemPrices)),
      }));
    };
  }, [itemsArr]);

  useEffect(() => {
    calculateTotalItemPrices();
  }, [itemsArr, calculateTotalItemPrices]);

  useEffect(() => {
    if (first) {
      POSTAPI_CreateInfoCsrAdmin().then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            allItems: res.items,
            dineInItems : res.dineInItems,
            allCombos: res.combos,
            allItemsCombos: res.items.concat(res.combos),
            first: false,
          }));
        }
      });

      GETAPI_OrderTypesCsr(`/order/orderType`).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            deliveryPartners: res.orderTypes,
          }));
        }
      });

      GETAPI_OrderTypesCsr(`/settings`).then((res) => {
        if (res.success) {
          setValues((prev) => ({
            ...prev,
            taxes: res.taxes,
          }));
        }
      });
    }
  }, [first]);

  //returns boolean checking order type is default partner or not
  const selectedPartner = useMemo(() => {
    if (deliveryPartners.length === 0) return false;
    return deliveryPartners.find((partner) => partner._id === orderTypeId)
      ?.isDefaultPartner;
  }, [orderTypeId, deliveryPartners]);

  const handleDiscountTypeChange = (e) => {
    setValues((x) => ({
      ...x,
      customDiscount: {
        discountType: e.target.value,
        discountValue: customDiscount.discountValue,
      },
    }));
  };

  const handleDiscountValueChange = (e) => {
    setValues((x) => ({
      ...x,
      customDiscount: {
        discountType: customDiscount.discountType,
        discountValue: e.target.value,
      },
    }));
  };

  const applyDiscount = () => {
    if (!customDiscount.discountValue && !customDiscount.discountType) {
      return;
    }

    if (customDiscount.discountType === "value") {
      if (
        parseFloat(customDiscount.discountValue) >
        parseFloat(currentItem.actualPrice)
      ) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Value can't be more than the product price.",
        }));
        return;
      }
      if (parseFloat(customDiscount.discountValue) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Value can't be less than 0.",
        }));
        return;
      }
    }

    if (customDiscount.discountType === "percent") {
      if (parseFloat(customDiscount.discountValue) > 100) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Can't apply more than 100%.",
        }));
        return;
      }
      if (parseFloat(customDiscount.discountValue) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Cant't apply less than 0.",
        }));
        return;
      }
    }

    const updatedArr = itemsArr.map((item) => {
      if (item.itemId && !item.identifierId) {
        if (item.itemId === currentItem.itemId && !currentItem.identifierId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.itemId && item.identifierId) {
        if (
          item.itemId === currentItem.itemId &&
          item.identifierId === currentItem.identifierId
        ) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.comboId && !item.identifierId) {
        if (item.comboId === currentItem.comboId && !currentItem.identifierId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.comboId && item.identifierId) {
        if (
          item.comboId === currentItem.comboId &&
          item.identifierId === currentItem.identifierId
        ) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        } else return item;
      }

      if (item.customId) {
        if (item.customId === currentItem.customId) {
          return {
            ...item,
            discount: {
              type: customDiscount.discountType,
              value: customDiscount.discountValue,
            },
          };
        }
      }
      return item;
    });

    setValues((x) => ({
      ...x,
      errorPosition: "",
      errorText: "",
    }));
    setValues((x) => ({ ...x, itemsArr: updatedArr }));
    setShowDiscountModal(false);
    Set_add_edit_visible(null);
    setValues((x) => ({
      ...x,
      customDiscount: { discountType: "percent", discountValue: null },
    }));
  };

  const handleAddItemBtn = () => {
    var passValidation = true;
    var validated = ValidateLetAddItemBtn(
      orderTypeId,
      name,
      phone,
      add_lat,
      add_lng,
      setValues,
      passValidation,
    );
    if (!validated) return;
    setValues((x) => ({
      ...x,
      itemModalVisible: true,
      errorPosition: "",
      errorText: "",
    }));
  };

  useEffect(() => {
    if (itemsArr.length === 0) {
      setValues((x) => ({ ...x, customDeliveryCharge: null }));
    }
  }, [itemsArr]);

  const closeModal = () => {
    setValues((val) => ({
      ...val,
      itemModalVisible: false,
      editItemModal: false,
      type: "",
      spiceLevel: 28,
      item: null,
      modifiersSel: [],
      quanSel: 1,
      thisItem: null,
      thisCombo: null,
      add_item_loading: false,
      thisCustomItem: null,
      errorPosition: "",
      errorText: "",
    }));
  };
  // single item select drop handle (for item select)
  const ItemSelectWithDetails = (e) => {
    const allItemsData = (salesType===ORDER_ORIGIN.DAY_SALES) ? dineInItems : allItems 
    var thisItem;
    var refer;
    thisItem = allItemsData.filter((x) => x._id === e.value);
    thisItem = thisItem.length > 0 ? thisItem[0] : null;
    if (!thisItem) return;
    refer = JSON.parse(JSON.stringify(thisItem));
    var allModifiersData = refer.modifiers;
    setValues((prev) => ({
      ...prev,
      item: e.value,
      thisItem: thisItem,
      allModifiers: allModifiersData,
      modifiersSel: allModifiersData,
    }));
  };

  const ComboSelected = (e) => {
    var thisComboItem;
    var refer;
    thisComboItem = allCombos.filter((x) => x._id === e.value);
    thisComboItem = thisComboItem.length > 0 ? thisComboItem[0] : null;
    if (!thisComboItem) return;
    refer = JSON.parse(JSON.stringify(thisComboItem));
    setValues((prev) => ({
      ...prev,
      item: e.value,
      thisCombo: refer,
    }));
    // handleChangeFun(mainValues => ({ ...mainValues, [varName]: e.value }))
  };

  const modifierSelected = (modInd, optionInd) => {
    var nowMods = [...modifiersSel];
    var selected = nowMods[modInd].options[optionInd];
    selected.sel = selected.sel ? false : true;
    setValues((prev) => ({ ...prev, modifiersSel: nowMods }));
  };

  const modifierSelectedEdit = (modIndex, optionsIndex) => {
    const newModifiers = _.cloneDeep(modifiersSel);
    const selected = newModifiers[modIndex].options[optionsIndex];
    selected.sel = !selected.sel;
    setValues((prev) => ({ ...prev, modifiersSel: newModifiers }));
  };

  const handleChangeSlide = (e, newVal) => {
    setValues((x) => ({ ...x, spiceLevel: newVal }));
  };

  // add item button at end
  const addItem = () => {
    if (type === "") return;
    if (type === "Combo Item" && !thisCombo) {
      return;
    }
    if (type === "Item" && !thisItem) {
      return;
    }
    if (type === "Custom Item" && !thisCustomItem) {
      return;
    }
    if (type === "Custom Item" && thisCustomItem) {
      if (!thisCustomItem.name || !thisCustomItem.price) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide name and price.",
        }));
        return;
      }
    }
    
    setValues((x) => ({ ...x, add_item_loading: true }));
    if (thisCustomItem) {
      //custom item
      let newCustomItem = {
        customId: uuidv4(),
        name: thisCustomItem.name,
        price: thisCustomItem.price,
        actualPrice: thisCustomItem.price,
        unitPrice: thisCustomItem.price,
        quantity: quanSel,
        spiceLevel: null,
        hasSpiceLevel: false,
        modifiers: [],
        isCooked: false,
      };

      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, newCustomItem],
      }));
      closeModal();
      return;
    }
    var thisItem_a = thisItem;
    var send_data = {
      name,
      phone,
      email,
      address: add_address,
      latitude: add_lat,
      longitude: add_lng,
      needCutlery,
      note,
      dispatchNote,
      orderTypeId,
    };
    var itemInfo = {};
    var comboInfo = {};

    let itemExists = false;
    let comboExists = false;

    if (thisItem_a) {
      //item
      const productExists = itemsArr.some(
        (item) => item.itemId === thisItem_a._id,
      );
      if (productExists) {
        if (
          !item.modifiers &&
          thisItem_a?.modifiers?.length === 0 &&
          thisItem_a?.hasSpiceLevel === false
        ) {
          setValues((x) => ({ ...x, add_item_loading: true }));
          closeModal();
          return;
        }
        itemExists = true;
      }
      thisItem_a.spiceLevel = getSpiceText(spiceLevel);
      var modsArr = [];
      if (modifiersSel.length > 0) {
        modifiersSel.forEach((element) => {
          var thisEl = { ...element };
          thisEl.options = [];
          element.options.forEach((op) => {
            thisEl.options.push({
              name: op.name,
              price: op.price,
              sel: op.sel === true ? true : false,
            });
          });
          if (thisEl.options.length > 0) {
            modsArr.push(thisEl);
          }
        });
      }
      itemInfo = {
        itemId: thisItem_a._id,
        quantity: quanSel,
        spiceLevel: thisItem.hasSpiceLevel ? getSpiceText(spiceLevel) : null,
        modifiers: modsArr,
        image: thisItem_a.image,
        name: thisItem_a.name,
        isCooked: false,
        price:
          !orderData.isAdminCreated && thisItem_a.hasDiscount
            ? thisItem_a.discountedPrice
            : thisItem_a.price,
        actualPrice:
          !orderData.isAdminCreated && thisItem_a.hasDiscount
            ? thisItem_a.discountedPrice
            : thisItem_a.price,
        hasDiscount: thisItem_a.hasDiscount,
        discountedPrice: thisItem_a.discountedPrice,
        hasSpiceLevel: thisItem_a.hasSpiceLevel,
      };
      if (itemExists) {
        itemInfo.identifierId = uuidv4();
      }
      if (!itemExists) {
        itemInfo.identifierId = null;
      }
      send_data = {
        ...send_data,
        itemInfo,
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, send_data.itemInfo],
      }));
      closeModal();
    } else {
      //combo

      thisItem_a = allCombos.filter((x) => x._id === item);
      if (thisItem_a.length === 0) return;
      thisItem_a = thisItem_a[0];
      const productExists = itemsArr.some(
        (item) => item.comboId === thisItem_a._id,
      );
      if (productExists) {
        if (
          !item.modifiers &&
          thisItem_a?.modifiers?.length === 0 &&
          thisItem_a?.hasSpiceLevel === false
        ) {
          setValues((x) => ({ ...x, add_item_loading: true }));
          closeModal();
          return;
        }
        comboExists = true;
      }
      comboInfo = {
        comboId: thisItem_a._id,
        quantity: quanSel,
        spiceLevel: getSpiceText(spiceLevel),
        image: thisItem_a.image,
        name: thisItem_a.name,
        price:
          !orderData.isAdminCreated && thisItem_a.hasDiscount
            ? thisItem_a.discountedPrice
            : thisItem_a.price,
        actualPrice:
          !orderData.isAdminCreated && thisItem_a.hasDiscount
            ? thisItem_a.discountedPrice
            : thisItem_a.price,
        hasDiscount: thisItem_a.hasDiscount,
        hasSpiceLevel: thisItem_a.hasSpiceLevel,
        discountedPrice: thisItem_a.discountedPrice,
        isCooked: false,
      };
      if (comboExists) {
        comboInfo.identifierId = uuidv4();
      }
      if (!comboExists) {
        comboInfo.identifierId = null;
      }
      send_data = {
        ...send_data,
        comboInfo,
      };
      setValues((x) => ({
        ...x,
        itemsArr: [...x.itemsArr, send_data.comboInfo],
      }));
      closeModal();
    }
  };
  // edit clicked in menu
  const editItemFn = (prod) => {
    if (prod.itemId) {
      var modTestArr = [];
      prod.allModifiers.forEach((element, ind) => {
        var selected = null;
        prod.modifiers.filter((x) => {
          if (x.modifierId === element.modifierId) {
            selected = x;
          }
          return selected;
        });
        if (selected) {
          //has modifier selected
          selected.options.forEach((op, i) => {
            var index = element.options.findIndex(
              (obj) => obj.name === op.name,
            );
            op.sel = true;
            element.options[index] = op;
          });
          modTestArr.push(element);
        } else modTestArr.push(element);
      });
      prod.modifiers = modTestArr;
      setValues((prev) => ({
        ...prev,
        item: prod.itemId,
        thisItem: prod,
        allModifiers: prod.allModifiers,
        modifiersSel: prod.modifiers,
        editItemModal: true,
        errorPosition: "",
        errorText: "",
        spiceLevel: prod.spiceLevel ? getSpiceValue(prod.spiceLevel) : null,
      }));
    } else if (prod.comboId) {
      setValues((prev) => ({
        ...prev,
        item: prod.comboId,
        thisCombo: prod,
        editItemModal: true,
        errorPosition: "",
        errorText: "",
        spiceLevel: prod.spiceLevel ? getSpiceValue(prod.spiceLevel) : null,
        quanSel: prod.quantity,
      }));
    } else return;
  };

  const updateFnAPI = () => {
    if (currentItem.itemId && !currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.itemId === currentItem.itemId && !item.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
              modifiers: modifiersSel?.length > 0 ? modifiersSel : [],
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr, modifiersSel: null }));
    }

    if (currentItem.itemId && currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.itemId === currentItem.itemId &&
        item.identifierId === currentItem.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
              modifiers: modifiersSel?.length > 0 ? modifiersSel : [],
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr, modifiersSel: null }));
    }

    if (currentItem.comboId && !currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.comboId === currentItem.comboId && !item.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr }));
    }

    if (currentItem.comboId && currentItem.identifierId) {
      const updatedArr = itemsArr.map((item) =>
        item.comboId === currentItem.comboId &&
        item.identifierId === currentItem.identifierId
          ? {
              ...item,
              spiceLevel: getSpiceText(spiceLevel),
            }
          : item,
      );

      // Update the itemsArr state with the updated array
      setValues((x) => ({ ...x, itemsArr: updatedArr }));
    }

    setShowEditModifiersModal(false);
    setValues((x) => ({ ...x, spiceLevel: 28 }));
  };

  const createFn = async () => {
    if (
      orderData.status === ORDER_STATUS.PENDING ||
      orderData.status === ORDER_STATUS.PICKED_UP ||
      orderData.status === ORDER_STATUS.COMPLETED ||
      orderData.status === ORDER_STATUS.CANCELED ||
      orderData.status === ORDER_STATUS.FAILED
    ) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Can't Edit this order",
      }));
      return;
    }

    if (orderData?.isAdminCreated) {
      if (!orderTypeId) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide a order type",
        }));
        return;
      }
    }

    if (paidAmount) {
      if (parseInt(paidAmount) > parseInt(calculatedTotalPrice)) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Paid amount can't be more than total price.",
        }));
        return;
      }
      if (parseInt(paidAmount) < 0) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Paid amount can't be less than 0.",
        }));
        return;
      }
    }

    if (itemsArr.length === 0) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Please provide an item",
      }));
      return;
    }

    if (orderData?.isAdminCreated) {
      if (selectedPartner) {
        const phonePattern = /^[9][6-9]\d{8}$/;

        if (phone === "") {
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: "Please provide phone number",
          }));
          return;
        }
        if (!phonePattern.test(phone)) {
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: "Please provide a valid phone number",
          }));
          return;
        }
      }
    } else {
      const phonePattern = /^[9][6-9]\d{8}$/;

      if (phone === "") {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide phone number",
        }));
        return;
      }
      if (!phonePattern.test(phone)) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: "Please provide a valid phone number",
        }));
        return;
      }
    }

    // reomve sel proprty and also remove those with sel prperty
    const updatedItemsArr = itemsArr.map((item) => {
      if (item.modifiers && item.modifiers.length > 0) {
        // Filter out options with sel set to false and remove the sel property
        const updatedModifiers = item.modifiers.map((modifier) => ({
          ...modifier,
          options: modifier.options
            .filter((option) => option.sel)
            .map(({ sel, ...rest }) => rest),
        }));
        // Remove modifiers with no selected options
        const filteredModifiers = updatedModifiers.filter(
          (modifier) => modifier.options.length > 0,
        );
        return {
          ...item,
          modifiers: filteredModifiers,
        };
      }
      return item;
    });

    const itemsInfo = [];
    const combosInfo = [];
    const customItemsInfo = updatedItemsArr
      .filter((item) => item?.customId)
      .map((item) => {
        const { spiceLevel, hasSpiceLevel, modifiers, ...rest } = item;
        return { ...rest };
      });

    updatedItemsArr.forEach((item) => {
      if (!item?.customId) {
        if (item.itemId) {
          itemsInfo.push(item);
        } else if (item.comboId) {
          combosInfo.push(item);
        }
      }
    });

    setValues((x) => ({ ...x, loading: true }));
    let data = !orderData?.isAdminCreated
      ? {
          name,
          phone,
          email,
          address: add_address,
          latitude: add_lat,
          longitude: add_lng,
          mapUrl,
          locationStatus,
          needCutlery,
          note,
          dispatchNote,
          itemsInfo,
          combosInfo,
          customItemsInfo,
          paidAmount: parseFloat(paidAmount),
          customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
        }
      : selectedPartner
        ? {
            orderTypeId,
            name,
            phone,
            email,
            address: add_address,
            latitude: add_lat,
            longitude: add_lng,
            mapUrl,
            locationStatus,
            needCutlery,
            note,
            dispatchNote,
            itemsInfo,
            combosInfo,
            customItemsInfo,
            paidAmount: parseFloat(paidAmount),
            customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
          }
        : {
            orderTypeId,
            note,
            dispatchNote,
            itemsInfo,
            combosInfo,
            customItemsInfo,
            paidAmount: parseFloat(paidAmount),
            customDeliveryCharge: Math.ceil(parseFloat(customDeliveryCharge)),
          };
    data.origin = salesType
    if (!isTextEmpty(orderId)) {
      //edit
      let edit_data = { ...data, orderId };
      POSTAPI_CSR_ADMIN("/order/admin/edit", edit_data).then((res) => {
        if (res.success) {
          setFetchOrder(true);
          setShowCreateOrder(true);
          setShowEditOrder(false);
          setCurrentId("");
          setValues((x) => ({ ...x, loading: false }));
        } else if (res.error) {
          setValues((x) => ({
            ...x,
            errorPosition: "apiErr",
            errorText: res.error,
            loading: false,
          }));
        }
        closeModal();
      });
    } else {
      setValues((x) => ({ ...x, loading: false }));
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModifiersModal(false);
    setValues((x) => ({
      ...x,
      currentItem: null,
      spiceLevel: null,
      modifiersSel: null,
    }));
  };

  const handleCloseDiscountModal = () => {
    setShowDiscountModal(false);
    setValues((x) => ({ ...x, currentItem: null }));
    setValues((x) => ({
      ...x,
      customDiscount: { discountType: "percent", discountValue: null },
      errorPosition: "",
      errorText: "",
    }));
  };

  const form = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <div>
        <InputLabelCsr
          label="Name"
          varName={name}
          errorPosition={errorPosition}
          errorText={errorText}
        />
        <InputButtonCsr name="name" value={name} setValues={setValues} />
      </div>
      <div>
        <InputLabelCsr
          label="Phone"
          varName={phone}
          errorPosition={errorPosition}
          errorText={errorText}
        />
        <InputButtonCsr name="phone" value={phone} setValues={setValues} />
      </div>
      <div>
        <InputLabelCsr
          label="Email (optional)"
          varName={email}
          errorPosition={errorPosition}
          errorText={errorText}
        />
        <InputButtonCsr name="email" value={email} setValues={setValues} />
      </div>
      <div>
        <InputLabelCsr label="Address co-ordinates" varName={coordinates} />
        <InputButtonCsr
          name="data"
          value={coordinates.data}
          setValues={setCoordinates}
          placeholder="27.683206, 85.313344"
        />
      </div>
      <div>
        <InputLabelCsr label="Address URL" varName={coordinates} />
        <InputButtonCsr name="mapUrl" value={mapUrl} setValues={setValues} />
      </div>
      <div>
        <InputLabelCsr label="Location Confirmation" varName={locationStatus} />
        <div style={{ paddingLeft: "0.4rem" }}>
          <RadioGroup
            aria-label="location-status"
            name="location-status"
            value={locationStatus}
            onChange={(e) => {
              setValues((x) => ({ ...x, locationStatus: e.target.value }));
            }}
            row={true}
          >
            <CustomFormControlLabel
              value="Verified"
              control={<CustomRadio />}
              label="Verified"
            />

            <CustomFormControlLabel
              value="Hold"
              control={<CustomRadio />}
              label="Hold"
            />
            <CustomFormControlLabel
              value="Not Verified"
              control={<CustomRadio />}
              label="Not Verified"
            />
          </RadioGroup>
        </div>
      </div>
      <p style={{ fontSize: "1.5rem", fontWeight: "100" }} className="mt-2">
        Additional Order Details
      </p>
      <div className="d-flex align-content-center">
        <ForkKnife size={25} color="#8943EF" />
        <div className="ml-2 w-100">
          <div className="d-flex justify-content-between">
            <span style={{ fontFamily: "Blender Pro thin", fontWeight: "100" }}>
              Cutlery
            </span>
            <CsrSwitch
              checked={needCutlery}
              onChange={() =>
                setValues((x) => ({
                  ...x,
                  needCutlery: !needCutlery,
                }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const subTotalWithTax = useMemo(() => {
    return taxes.reduce((subtotal, item) => {
      if (item.type === "percent") {
        const taxAmount =
          (parseFloat(item.amount) / 100) * parseFloat(subTotalPrice);
        return parseFloat(subtotal + taxAmount);
      }
      if (item.type === "value") {
        const taxAmount = parseFloat(item.amount);
        return parseFloat(subtotal + taxAmount);
      }
      return subtotal;
    }, parseFloat(subTotalPrice));
  }, [subTotalPrice, taxes]);

  const calculatedTotalPrice = useMemo(() => {
    let totalPrice = 0;

    if (taxes && taxes.length > 0) {
      totalPrice =
        customDeliveryCharge > 0 && customDeliveryCharge !== null
          ? parseFloat(
              parseFloat(subTotalWithTax) +
                parseFloat(customDeliveryCharge) -
                parseFloat(promoAmount),
            )
          : parseFloat(parseFloat(subTotalWithTax) - parseFloat(promoAmount));
    } else {
      totalPrice =
        customDeliveryCharge > 0
          ? parseFloat(
              parseFloat(subTotalPrice) +
                parseFloat(customDeliveryCharge) -
                parseFloat(promoAmount),
            )
          : parseFloat(parseFloat(subTotalPrice) - parseFloat(promoAmount));
    }

    if (orderData?.payment?.tipAmount > 0)
      totalPrice += orderData?.payment?.tipAmount;

    return totalPrice.toFixed(2);
  }, [
    taxes,
    customDeliveryCharge,
    subTotalWithTax,
    subTotalPrice,
    promoAmount,
    orderData,
  ]);

  if (data_loading) {
    return (
      <>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <CircularProgress style={{ color: "#00f0ff" }} />
        </div>
        {AlertErrorCsr(errorPosition, errorText, setValues)}
      </>
    );
  }

  if (id && Object.keys(orderData).length === 0) {
    return (
      <>
        <div className="w-100 h-100 d-flex justify-content-center align-items-center m-auto text-white csr_little_texts">
          No order details found
        </div>
        {AlertErrorCsr(errorPosition, errorText, setValues)}
      </>
    );
  }

  const addItemsByType = () => {
    switch (salesType) {
      case 'admin':
        return allItems.concat(allCombos)
      case 'website':
        return allItems.concat(allCombos)
      case 'app':
        return allItems.concat(allCombos)
      case 'day_sales':
        return dineInItems.concat(allCombos)
      case 'night_sales':
        return allItems.concat(allCombos)
      default:
        return []
    }
  };

  const AllItemSelectWithDetails = (e) => {
    const allItemsData = (salesType===ORDER_ORIGIN.DAY_SALES) ? dineInItems : allItems 
    var foundItem = allItemsData.find((x) => x._id === e.value);
    var foundCombo = allCombos.find((x) => x._id === e.value);
    if(foundItem) {
      setValues(x=>({...x, type: "Item"}))
      ItemSelectWithDetails(e)
    }
    else if(foundCombo) {
      setValues(x=>({...x, type: "Combo Item"}))
      ComboSelected(e)
    } 
    return
  };

  const addCustomItem = () => {
    setValues(x=>({...x, type: "Custom Item"}))
  }

  const AddCustomItemDiv = () => {
    return(
    <div className="d-flex flex-column mt-2">
      <InputLabelCsr
        label="Name"
        varName={name}
        errorPosition={errorPosition}
        errorText={errorText}
      />
      <TextField
        type="text"
        value={thisCustomItem?.name || ""}
        sx={{
          border: "1px solid #545356",
        }}
        className="custom-text-field"
        onChange={(e) => {
          setValues((x) => ({
            ...x,
            thisCustomItem: {
              ...x.thisCustomItem,
              name: e.target.value,
            },
          }));
        }}
        InputProps={{
          style: {
            borderRadius: "0px",
            outline: "none !important",
            color: "white",
            height: "2.5rem",
            fontFamily: "Blender Pro thin",
            "&:focus": {
              outline: "none !important",
            },
          },
        }}
      />
      <div className="mt-2" />
      <InputLabelCsr label="Price" />
      <TextField
        type="number"
        sx={{
          border: "1px solid #545356",
        }}
        value={thisCustomItem?.price || null}
        className="custom-text-field"
        onChange={(e) => {
          setValues((x) => ({
            ...x,
            thisCustomItem: {
              ...x.thisCustomItem,
              price: e.target.value,
            },
          }));
        }}
        InputProps={{
          style: {
            borderRadius: "0px",
            outline: "none !important",
            color: "white",
            height: "2.5rem",
            fontFamily: "Blender Pro thin",
            "&:focus": {
              outlineColor: "none !important",
            },
          },
        }}
      />
      <div className="mt-2" />
      {thisCustomItem && thisCustomItem.name && (
        <>
          <p className="csr_little_texts">Quantity</p>
          {CartQuantityCsr(quanSel, setValues)}
        </>
      )}
    </div>
    )
  }

  const AllItemsSelectDropdown = () => {
    return (
      <div>
      {type === "Custom Item" && AddCustomItemDiv()}
        {/* item */}
       {type !=="Custom Item" && <InputLabelCsr
          label="Item"
          varName={item}
          errorPosition={errorPosition}
          errorText={errorText}
        />}
        {type !=="Custom Item" && <Select
          autoFocus
          styles={customStyles}
          maxMenuHeight={"200px"}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          options={addItemsByType().map((item) =>
            item._id ? { label: item.name, value: item._id } : item,
          )}
          value={addItemsByType()
            .filter((option) => option._id === item)
            .map((item) => ({ label: item.name, value: item._id }))}
          name={"item"}
          onChange={(e) => AllItemSelectWithDetails(e)}
          className="mb-2"
        />}
        {type !=="Custom Item" && thisItem && (
          <>
            <p className="mt-3 mb-2 csr_little_texts">
              Price: Rs. {thisItem.price}
            </p>
            <p className="csr_little_texts">Quantity</p>
            {CartQuantityCsr(quanSel, setValues)}
          </>
        )}
        {type !=="Custom Item" && thisItem &&
          thisItem.modifiers &&
          thisItem.modifiers.length > 0 && (
            <>
              <FormGroup className="mt-3 csr_little_texts">
                {thisItem.modifiers.map((each, i) => (
                  <div key={i}>
                    <p
                      className="csr_little_texts"
                      style={{ color: "#a2a2a2" }}
                    >
                      Modifier {i + 1}
                    </p>
                    <div className="d-flex flex-column csr_little_texts_moderate csr_little_texts_moderate">
                      {each.options &&
                        each.options.map((eachOP, ind) => {
                          return (
                            <div
                              key={ind}
                              className="d-flex justify-content-between align-items-center csr_little_texts"
                            >
                              <CustomFormControlLabel
                                key={ind}
                                control={
                                  <CustomCheckbox
                                    // sx={{
                                    //   color: "#8943EF",
                                    //   "&.Mui-checked": {
                                    //     color: "#8943EF",
                                    //   },
                                    //   fontFamily: "Blender Pro thin",
                                    // }}
                                    // checked={checkedOrNot(ind, thisItem.modifiersArr_Selected[i])}
                                    value={each.sel}
                                    onClick={() =>
                                      modifierSelected(i, ind, thisItem)
                                    }
                                    inputProps={{
                                      "aria-label": "controlled",
                                    }}
                                  />
                                }
                                label={eachOP.name}
                              />
                              <span>Rs. {eachOP.price}</span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ))}
              </FormGroup>
            </>
          )}
        {/* spice level */}
        {type !=="Custom Item" && thisItem && thisItem.hasSpiceLevel && (
          <>
            {" "}
            <p className="spiceLevel_text_csr ml-auto mr-auto mt-4 text-center csr_little_texts">
              {getSpiceText(spiceLevel)}
            </p>
            <SliderMUI
              defaultValue={[20, 40]}
              value={spiceLevel}
              color="error"
              aria-label="modifier"
              step={null}
              onChange={handleChangeSlide}
              valueLabelDisplay="off"
              marks={[0, 28, 66, 100].map((each) => ({
                value: each,
                label: "",
              }))}
            />
          </>
        )}
      </div>
    )
  }

  return (
    <>
      <ThemeProvider theme={csrTheme}>
        <div className="csr_create_order_header d-flex justify-content-between align-content-center ">
        <IconButton className="text-white ipadPort mr-3" onClick={e=>setView(false)}>
                    <ArrowLeft />
                </IconButton>
          <div
            className=" d-flex justify-content-between align-content-center  gap-4 "
            style={{
              height: "2.3rem",
              width: "fit-content",
            }}
          >
            <div className="csr_create_order_header_titles">
              {ShowOriginLogo(orderData?.origin)}
              &nbsp; #ORD-{orderData?.trackingNumber}
            </div>

            {orderData.isAdminCreated ? (
              <>
                <select
                  name="orderTypeId"
                  id="orderTypeId"
                  className="select"
                  value={orderTypeId}
                  onChange={(e) =>
                    setValues((x) => ({
                      ...x,
                      orderTypeId: e.target.value,
                    }))
                  }
                >
                  <option className="select_option" disabled value="">
                    Select an option
                  </option>

                  {deliveryPartners.map(({ _id, name }) => {
                    return (
                      <option key={_id} value={_id} className="select_option">
                        {name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : null}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  width: "110px",
                  paddingInline: "10px",
                  outline: "0",
                }}
                className={statusButtonClass(orderData.status)[0]}
              >
                {statusButtonClass(orderData.status)[1]}
              </Button>
            </div>
          </div>

          <IconButton
            onClick={() => {
              setShowCreateOrder(true);
              setShowEditOrder(false);
              setCurrentId("");
              setShowViewOrder(false);
              setRefetchEdit && setRefetchEdit(false);
            }}
          >
            <X color="#FFFFFF" />
          </IconButton>
        </div>

        <div className="csr_create_div csr_create_div_2">
          <div className="adoc_left">
            <p style={{ fontSize: "1.5rem" }}>
              {orderTypeId && selectedPartner ? "Customer" : "Order"} Details
            </p>

            <p className="s2 w3">
              {orderTypeId === ""
                ? form
                : orderTypeId.length > 0 && selectedPartner && form}
            </p>

            <InputLabelCsr label="Note" varName={note} />
            <InputCompTextAreaCsr
              name="note"
              value={note}
              setValues={setValues}
              className="csr_text_area"
            />
            <InputLabelCsr label="Dispatch Note" varName={dispatchNote} />
            <InputCompTextAreaCsr
              name="dispatchNote"
              value={dispatchNote}
              setValues={setValues}
              className="csr_text_area"
            />
          </div>

          <div className="adoc_left">
            {itemsArr && itemsArr.length > 0 && (
              <div className="adocr_right_items_div_csr">
                {itemsArr.map((prod, ind) => {
                  let totalCostModifiers = 0;

                  if (prod?.modifiers) {
                    for (const modifier of prod.modifiers) {
                      for (const option of modifier.options) {
                        if (option.sel) {
                          totalCostModifiers += option.price;
                        } else {
                          totalCostModifiers += 0;
                        }
                      }
                    }
                  }

                  const percentPrice =
                    prod.discount && prod.discount.value
                      ? Math.ceil(parseFloat(prod.actualPrice)) -
                        (Math.ceil(parseFloat(prod.discount.value)) / 100) *
                          prod.actualPrice
                      : 0;
                  const percentPriceWithModifiers =
                    percentPrice +
                    (prod?.modifiers?.length > 0
                      ? Math.ceil(parseFloat(totalCostModifiers))
                      : 0);
                  const valuePrice = prod?.discount?.value
                    ? Math.ceil(parseFloat(prod?.actualPrice)) -
                      Math.ceil(parseFloat(prod?.discount?.value))
                    : 0;
                  const valuePriceWithModifiers =
                    valuePrice +
                    (prod?.modifiers?.length > 0
                      ? Math.ceil(parseFloat(totalCostModifiers))
                      : 0);

                  return (
                    <div className="eachCart_div" key={ind}>
                      <div className="mb-2 d-flex" key={ind}>
                        <div className="d-flex flex-column w-100 justify-content-center">
                          <div className="d-flex justify-content-between align-items-center">
                            <span className="csr_little_texts_moderate">
                              {prod.name}
                            </span>
                            {AdOrderEditDeleteMenuCsr(
                              prod,
                              editItemFn,
                              add_edit_visible_ind,
                              Set_add_edit_visible,
                              ind,
                              itemsArr,
                              setValues,
                              setShowDiscountModal,
                              setShowEditModifiersModal,
                              orderData,
                            )}
                          </div>
                          <div className="d-flex w-100 justify-content-between mt-2">
                            {/* <span>Qty : {prod.quantity}</span> */}
                            {CartQuantityAdminArrCsr(
                              prod,
                              itemsArr,
                              setValues,
                              editing,
                            )}
                            <span className="eachCart_price c4 csr_little_texts_moderate">
                              {prod.discount &&
                              prod.discount.type === "percent" &&
                              prod.discount.value !== null &&
                              prod.discount.value > 0 ? (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      Math.ceil(
                                        parseFloat(percentPriceWithModifiers),
                                      ) * prod.quantity,
                                    ),
                                  )}
                                  <del
                                    className="ml-1 csr_little_texts_moderate"
                                    style={{ color: "#fff" }}
                                  >
                                    Rs.{" "}
                                    {Math.ceil(
                                      parseFloat(
                                        (Math.ceil(
                                          parseFloat(prod.actualPrice),
                                        ) +
                                          Math.ceil(
                                            parseFloat(totalCostModifiers),
                                          )) *
                                          prod.quantity,
                                      ),
                                    )}
                                  </del>
                                </>
                              ) : prod.discount &&
                                prod.discount.type === "value" &&
                                prod.discount.value !== null &&
                                prod.discount.value > 0 ? (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      Math.ceil(
                                        parseFloat(valuePriceWithModifiers),
                                      ) * prod.quantity,
                                    ),
                                  )}
                                  <del
                                    className="ml-1 csr_little_texts_moderate"
                                    style={{ color: "#fff" }}
                                  >
                                    Rs.{" "}
                                    {Math.ceil(
                                      parseFloat(
                                        (Math.ceil(
                                          parseFloat(prod.actualPrice),
                                        ) +
                                          Math.ceil(
                                            parseFloat(totalCostModifiers),
                                          )) *
                                          prod.quantity,
                                      ),
                                    )}
                                  </del>
                                </>
                              ) : (
                                <>
                                  Rs.{" "}
                                  {Math.ceil(
                                    parseFloat(
                                      (Math.ceil(parseFloat(prod.actualPrice)) +
                                        Math.ceil(
                                          parseFloat(totalCostModifiers),
                                        )) *
                                        prod.quantity,
                                    ),
                                  )}{" "}
                                </>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* customizations */}
                      {((prod.modifiers &&
                        prod.modifiers
                          .map((item) => ({
                            ...item,
                            options: item.options.filter(
                              (option) => option.sel,
                            ),
                          }))
                          .filter((item) => item.options.length > 0).length >
                          0) ||
                        prod.hasSpiceLevel === true) && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={
                              <CaretDown weight="bold" className="ml-2" />
                            }
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            style={{
                              color: "#a2a2a2",
                              fontSize: "1rem",
                              fontWeight: "400",
                            }}
                          >
                            Customizations
                          </AccordionSummary>
                          <AccordionDetails>
                            {prod.modifiers && prod.modifiers.length > 0 && (
                              <>
                                {prod.modifiers
                                  .map((item) => ({
                                    ...item,
                                    options: item.options.filter(
                                      (option) => option.sel,
                                    ),
                                  }))
                                  .filter((item) => item.options.length > 0)
                                  .map((each, i) => (
                                    <div key={i}>
                                      <p
                                        className="csr_little_texts"
                                        style={{ color: "#a2a2a2" }}
                                      >
                                        Modifier {i + 1}
                                      </p>
                                      <div className="d-flex flex-column mt-2 csr_little_texts_moderate">
                                        {each.options &&
                                          each.options.map((eachOP, ind) => {
                                            return (
                                              <div
                                                key={ind}
                                                className="
                                                                  d-flex justify-content-between
                                                                   align-items-center mb-2"
                                              >
                                                <span>{eachOP.name}</span>
                                                <span>Rs. {eachOP.price}</span>
                                              </div>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  ))}
                              </>
                            )}
                            {prod.hasSpiceLevel && prod.spiceLevel !== null && (
                              <div className={"d-flex flex-column"}>
                                <span
                                  className="mb-1"
                                  style={{
                                    color: "#a2a2a2 !important",
                                    fontSize: "0.9rem",
                                    fontWeight: "400",
                                    fontFamily: "Blender Pro thin",
                                  }}
                                >
                                  Spice Level
                                </span>
                                <span className="tile_mod_text">
                                  {prod.spiceLevel}
                                </span>
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                  );
                })}

                <div className="mt-4 mb-1 border-top" />
                <div className="d-flex flex-column pl-3 pr-3 w-100 justify-content-between align-items-center mt-4">
                  <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                    <div>Subtotal</div>
                    <div className="fs-6 fw-semibold ">
                      Rs. {Math.ceil(parseFloat(subTotalPrice))}
                    </div>
                  </div>
                  {taxes &&
                    taxes.length > 0 &&
                    taxes.map((item) => {
                      return (
                        <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                          <div>{item.name}</div>
                          <div className="fs-6 fw-semibold ">
                            {item.type === "value" && `Rs. ${item.amount}`}
                            {item.type === "percent" &&
                              `Rs. ${parseFloat(
                                (parseFloat(item.amount) / 100) * subTotalPrice,
                              ).toFixed(2)} (${item.amount} %)`}
                          </div>
                        </div>
                      );
                    })}

                  {orderData?.payment?.tipAmount > 0 && (
                    <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                      <div>Rider Tip</div>
                      <div className="fs-6 fw-semibold ">
                        Rs. {orderData?.payment?.tipAmount}
                      </div>
                    </div>
                  )}

                  {orderData?.payment?.hasOwnProperty("promoDiscount") &&
                  orderData?.payment?.promoDiscount &&
                  orderData?.payment?.promoDiscount > 0 ? (
                    <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                      <div>Promo discount</div>
                      <div className="fs-6 fw-semibold ">
                        Rs. {parseFloat(orderData.payment?.promoDiscount)}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {orderData.isAdminCreated ? (
                    selectedPartner ? (
                      <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                        <div>Delivery charge </div>
                        <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                          <div className="mr-2">Rs.{"   "}</div>
                          <TextField
                            type="number"
                            sx={{
                              border: "1px solid #545356",
                            }}
                            placeholder="Amount..."
                            value={customDeliveryCharge}
                            onChange={(e) => {
                              setValues((x) => ({
                                ...x,
                                customDeliveryCharge: e.target.value,
                              }));
                            }}
                            InputProps={{
                              style: {
                                borderRadius: "0px",
                                width: "90px",
                                height: "30px",
                                color: "white",
                                fontFamily: "Blender Pro thin",
                                "&:focus": {
                                  outline: "none !important",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )
                  ) : (
                    <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                      <div>Delivery charge </div>
                      {/* <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                        <div className="mr-2">Rs.{"   "}</div>
                        <div>{customDeliveryCharge}</div>
                      </div> */}
                      <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                        <div className="mr-2">Rs.{"   "}</div>
                        <TextField
                          type="number"
                          sx={{
                            border: "1px solid #545356",
                          }}
                          placeholder="Amount..."
                          value={customDeliveryCharge}
                          onChange={(e) => {
                            setValues((x) => ({
                              ...x,
                              customDeliveryCharge: e.target.value,
                            }));
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "0px",
                              width: "90px",
                              height: "30px",
                              color: "white",
                              fontFamily: "Blender Pro thin",
                              "&:focus": {
                                outline: "none !important",
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                    <div>Paid Amount </div>
                    <div className="fs-6  fw-semibold d-flex flex-row justify-content-center align-items-center">
                      <div className="mr-2">Rs.{"   "}</div>
                      <TextField
                        type="number"
                        sx={{
                          border: "1px solid #545356",
                        }}
                        value={paidAmount === 0 ? "" : paidAmount}
                        onChange={(e) => {
                          setValues((x) => ({
                            ...x,
                            paidAmount: e.target.value,
                          }));
                        }}
                        InputProps={{
                          style: {
                            borderRadius: "0px",
                            width: "90px",
                            height: "30px",
                            color: "white",
                            fontFamily: "Blender Pro thin",
                            "&:focus": {
                              outline: "none !important",
                            },
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div
                    className="d-flex  flex-row w-100 mt-3 justify-content-between align-items-center"
                    style={{ fontFamily: "Blender Pro thin" }}
                  >
                    <div>Total</div>
                    <div
                      className="fs-3 fw-bold "
                      style={{ fontFamily: "Blender Pro thin" }}
                    >
                      Rs. {calculatedTotalPrice}
                    </div>
                  </div>
                  {paidAmount && parseFloat(paidAmount) > 0 ? (
                    <>
                      <div className="d-flex flex-row w-100 mt-1 justify-content-between align-items-center">
                        <div>Remaining Amount </div>
                        <div className="fs-3 fw-bold ">
                          Rs.{" "}
                          {parseFloat(
                            parseInt(calculatedTotalPrice) -
                              parseFloat(paidAmount),
                          ).toFixed(2)}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}

            <Button
              className="csr_add_item mt-3"
              onClick={() => handleAddItemBtn()}
            >
              <Plus color="#FCEE0A" size={15} /> Add Item
            </Button>

            {itemsArr && itemsArr.length > 0 && (
              <div className="mt-auto">
                {loading ? (
                  <>
                    <div className="half_right_btn_comp_csr">
                      <CircularProgress style={{ color: "#00F0FF" }} />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="half_right_btn_comp_csr"
                      onClick={() => createFn()}
                    >
                      {" "}
                      {SvgButtonCSR("UPDATE")}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <CsrModal
          open={itemModalVisible}
          onClose={() => closeModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* top */}
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title csr_little_texts">Add Item</span>
              {
              type==="Custom Item" ?
              <div className="ml-auto" style={{
                border:'1px solid #00f0ff',
                color:'#00f0ff',
                padding:'2px 4px',
                borderRadius:'5px',
                cursor:'pointer'
              }} onClick={() => setValues(x=>({...x, type: ""}))}>
                Remove Custom X
              </div>
            :
              <div className="ml-auto" style={{
                border:'1px solid #00f0ff',
                color:'#00f0ff',
                padding:'2px 4px',
                borderRadius:'5px',
                cursor:'pointer'
              }} onClick={() => addCustomItem()}>
                + Custom Item
              </div>
            }
              <IconButton onClick={() => closeModal()}>
                <X color="#FFFFFF" />
              </IconButton>
            </div>

            {/* all items new */}
            {AllItemsSelectDropdown()}

            {/* end */}
            <div className="d-flex justify-content-end mt-4 w-100 gap-4 ">
              <Button className="adocr_cancel_csr" onClick={() => closeModal()}>
                CANCEL
              </Button>
              {add_item_loading ? (
                <div className="adocr_add_btn_csr">
                  <CircularProgress style={{ color: "#00F0FF" }} />
                </div>
              ) : (
                <div className="adocr_add_btn_csr" onClick={() => addItem()}>
                  {" "}
                  {SvgButtonCSR("ADD")}
                </div>
              )}
            </div>
          </Box>
        </CsrModal>

        {/* Discount Item Modal */}
        <CsrModal
          open={showDiscountModal}
          onClose={() => handleCloseDiscountModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title csr_create_order_header_titles">
                Add Discount
              </span>
              <IconButton onClick={() => handleCloseDiscountModal()}>
                <X color="#FFFFFF" />
              </IconButton>
            </div>

            <FormGroup className="mt-3">
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="discount-type"
                  name="discount-type"
                  value={customDiscount.discountType}
                  onChange={handleDiscountTypeChange}
                >
                  <CustomFormControlLabel
                    value="percent"
                    control={<CustomRadio />}
                    label="Percent"
                  />
                  <CustomFormControlLabel
                    value="value"
                    control={<CustomRadio />}
                    label="Value"
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                type="number"
                sx={{
                  border: "1px solid #545356",
                  marginTop: "10px",
                }}
                value={customDiscount.discountValue}
                onChange={handleDiscountValueChange}
                InputProps={{
                  endAdornment:
                    customDiscount.discountType === "percent" ? "%" : "Rs",
                  style: {
                    borderRadius: "0px",
                    borderColor: "#545356",
                    fontFamily: "Blender Pro thin",

                    color: "white",
                    "&:focus": {
                      outline: "0",
                    },
                  },
                }}
              />
            </FormGroup>

            <div className="d-flex justify-content-end mt-4 gap-4">
              <Button
                className="adocr_cancel_csr"
                onClick={() => handleCloseDiscountModal()}
              >
                {" "}
                Cancel
              </Button>

              <div
                className="adocr_add_btn_csr"
                onClick={() => applyDiscount()}
              >
                {" "}
                {SvgButtonCSR("APPLY")}
              </div>
            </div>
          </Box>
        </CsrModal>

        {/* Edit Item Modal */}
        <CsrModal
          open={showEditModifiersModal}
          onClose={() => handleCloseEditModal()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {/* top */}
            <div className="d-flex justify-content-between align-items-center">
              <span className="ad_body_title csr_create_order_header_titles">
                Edit Customizations
              </span>
              <IconButton onClick={() => handleCloseEditModal()}>
                <X color="#FFFFFF" />
              </IconButton>
            </div>

            {currentItem &&
              currentItem?.modifiers &&
              currentItem?.modifiers?.length > 0 && (
                <>
                  <FormGroup className="mt-3">
                    {currentItem &&
                      currentItem.modifiers.map((each, i) => {
                        return (
                          <div key={i}>
                            <p
                              className="csr_little_texts"
                              style={{ color: "#a2a2a2" }}
                            >
                              Modifier {i + 1}
                            </p>
                            <div className="d-flex flex-column">
                              {each.options &&
                                each.options.map((item, ind) => {
                                  return (
                                    <div
                                      key={ind}
                                      className="
                                                          d-flex justify-content-between
                                                           align-items-center"
                                    >
                                      <CustomFormControlLabel
                                        key={ind}
                                        control={
                                          <CustomCheckbox
                                            sx={{
                                              color: "#8943EF",
                                              "&.Mui-checked": {
                                                color: "#8943EF",
                                              },
                                            }}
                                            defaultChecked={item.sel}
                                            value={item.sel}
                                            onChange={() =>
                                              modifierSelectedEdit(
                                                i,
                                                ind,
                                                currentItem,
                                              )
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                          />
                                        }
                                        label={item.name}
                                      />
                                      <span className="csr_little_texts">
                                        Rs. {item.price}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        );
                      })}
                  </FormGroup>
                </>
              )}

            {/* spice level */}
            {currentItem &&
              currentItem.hasSpiceLevel === true &&
              !isTextEmpty(currentItem.spiceLevel) && (
                <>
                  {" "}
                  <p className="spiceLevel_text_csr ml-auto mr-auto mt-4 text-center csr_little_texts">
                    {getSpiceText(spiceLevel)}
                  </p>
                  <SliderMUI
                    defaultValue={[20, 40]}
                    value={spiceLevel}
                    color="error"
                    aria-label="modifier"
                    step={null}
                    onChange={handleChangeSlide}
                    valueLabelDisplay="off"
                    marks={[0, 28, 66, 100].map((each) => ({
                      value: each,
                      label: "",
                    }))}
                  />
                </>
              )}

            {/* end */}
            <div className="d-flex justify-content-end mt-3 gap-4">
              <Button
                className="adocr_cancel_csr"
                onClick={() => handleCloseEditModal()}
              >
                {" "}
                Cancel
              </Button>

              <div className="adocr_add_btn_csr" onClick={() => updateFnAPI()}>
                {" "}
                {SvgButtonCSR("UPDATE")}
              </div>
            </div>
          </Box>
        </CsrModal>
      </ThemeProvider>
      {AlertErrorCsr(errorPosition, errorText, setValues)}
    </>
  );
};

export const ViewOrder = ({
  currentId,
  setCurrentId,
  setShowCreateOrder,
  setFetchOrder,
  setFetchDraft,
  setShowEditOrder,
  setShowViewOrder,
  setRefetchEdit,
  setView
}) => {
  let id = currentId;

  useEffect(() => {
    if (!currentId) {
      setShowCreateOrder(true);
      setShowEditOrder(false);
      setShowCreateOrder(false);
      setCurrentId("");
    }
  }, [currentId, setShowCreateOrder, setShowEditOrder, setCurrentId]);

  const [values, setValues] = useState({
    stateData: null,
    assignTo: null,
    distance: "",
    errorPosition: "",
    errorText: "",
    changeDriverModal: false,
    riders: null,
    assign_loading: false,
    data_loading: true,
    anchorElChangeStatus: null,
    anchorElChangePriority: null,
    changeState_loading: false,
    changePriority_loading: false,
    showStatusFailedModal: false,
    showStatusCompleteModal: false,
    note: "",
    qrAmount: 0,
    cashAmount: 0,
    unpaidAmount: 0,
    tipAmount: 0,
    driverPaymentStatus: "",
    userDetails: null,
    blackListing_loading: false,
    isUserBanned: false,
    showBanButton: false,
  });
  const {
    stateData,
    assignTo,
    distance,
    changeDriverModal,
    errorPosition,
    errorText,
    riders,
    data_loading,
    assign_loading,
    anchorElChangeStatus,
    anchorElChangePriority,
    changeState_loading,
    changePriority_loading,
    showStatusFailedModal,
    showStatusCompleteModal,
    note,
    qrAmount,
    cashAmount,
    unpaidAmount,
    tipAmount,
    driverPaymentStatus,
    userDetails,
    blackListing_loading,
    isUserBanned,
    showBanButton,
  } = values;
  const openChangeStatus = Boolean(anchorElChangeStatus);
  const openChangePriority = Boolean(anchorElChangePriority);
  const priority = stateData?.priorityLevel ?? ''

  useEffect(() => {
    if (!id) {
      setShowCreateOrder(true);
      setShowEditOrder(false);
      setShowCreateOrder(false);
      setCurrentId("");
    }
  }, [id, setShowCreateOrder, setShowEditOrder, setCurrentId]);

  useEffect(() => {
    setValues((x) => ({
      ...x,
      data_loading: true,
      stateData: null,
      assignTo: null,
      errorPosition: "",
      errorText: "",
      changeDriverModal: false,
      riders: null,
      assign_loading: false,
      anchorElChangeStatus: null,
      changeState_loading: false,
      userDetails: null,
      blackListing_loading: false,
      isUserBanned: false,
      showBanButton: false,
    }));
  }, [currentId]);

  const fetchViewOrder = useCallback(() => {
    POSTAPI_CSR_ADMIN("/order/one", { orderId: id }).then((res) => {
      if (res.success) {
        setValues((x) => ({
          ...x,
          stateData: res.order,
          data_loading: false,
          driverPaymentStatus: res?.order?.delivery?.riderPaidStatus || "",
          qrAmount:
            res?.order?.paymentInfo?.find((i) => i.method === "Qr")?.amount ||
            0,
          cashAmount:
            res?.order?.paymentInfo?.find((i) => i.method === "Cash")?.amount ||
            0,
          note: res?.order?.delivery?.failReason || "",
          unpaidAmount:
            res.order.status === "Completed"
              ? res?.order?.payment?.remainingAmount
              : 0 || 0,
        }));
        if (!res.order?.isAdminCreated) {
          setValues((x) => ({ ...x, showBanButton: true }));
        }
        if (
          res.order?.isAdminCreated &&
          res.order?.delivery &&
          res.order?.delivery?.deliveryPartner?.isDefaultPartner
        ) {
          setValues((x) => ({ ...x, showBanButton: true }));
        }
        if (
          res.order &&
          res?.order?.delivery &&
          res?.order?.delivery?.location &&
          res?.order?.delivery?.location?.phone
        ) {
          POSTAPI_CSR_ADMIN("/user/checkValidUser", {
            contact: res.order?.delivery?.location?.phone,
          }).then((res) => {
            if (res.success) {
              setValues((x) => ({
                ...x,
                userDetails: res,
                isUserBanned: res.user ? res.user?.isBanned : false,
              }));
            }
          });
        }
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          data_loading: false,
        }));
    });
  }, [id, setValues]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchViewOrder();
    }, 60000);

    fetchViewOrder();

    POSTAPI_CSR_ADMIN("/rider/orders").then((res) => {
      if (res.success) {
        setValues((x) => ({
          ...x,
          riders: res.ridersInfo,
        }));
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
        }));
    });

    return () => clearInterval(intervalId);
  }, [id, fetchViewOrder]);

  const copyGoogleLocation = () => {
    if (
      stateData.delivery.location &&
      stateData.delivery.location.latitude &&
      stateData.delivery.location.longitude
    ) {
      var lat = stateData.delivery.location.latitude;
      var long = stateData.delivery.location.longitude;
      var googleLocation = `https://www.google.com/maps/search/?api=1&query=${lat},${long}`;
      navigator.clipboard.writeText(googleLocation);
      setValues((x) => ({
        ...x,
        errorPosition: "success",
        errorText: "Copied location",
      }));
    }
  };

  const handleCloseModal = () => {
    setValues((x) => ({
      ...x,
      showStatusCompleteModal: false,
      showStatusFailedModal: false,
      anchorElChangeStatus: null,

      changeState_loading: false,
    }));
  };

  const copyMapUrl = (url) => {
    navigator.clipboard.writeText(url);
    setValues((x) => ({
      ...x,
      errorPosition: "success",
      errorText: "Copied location",
    }));
  };

  const blackList = () => {
    setValues((x) => ({
      ...x,
      blackListing_loading: true,
    }));
    POSTAPI_CSR_ADMIN("/user/banUnBanUser", {
      // userId: stateData?.userInfo?.userId || "",
      phone: stateData?.delivery?.location?.phone || "",
    }).then((res) => {
      if (res.success) {
        setValues((x) => ({
          ...x,
          isUserBanned: res.user ? res.user?.isBanned : false,
          blackListing_loading: false,
        }));
        fetchViewOrder();
        if (res.user?.isBanned) {
          setValues((x) => ({
            ...x,

            errorPosition: "success",
            errorText: "This user has been blackListed",
          }));
        }
        if (!res.user?.isBanned) {
          setValues((x) => ({
            ...x,

            errorPosition: "success",
            errorText: "This user has been removed from blackList",
          }));
        }
      } else if (res.error)
        setValues((prev) => ({
          ...prev,
          errorPosition: "apiErr",
          errorText: res.error,
          blackListing_loading: false,
        }));
    });
  };

  const UserDetails = () => {
    var user = stateData;

    if (user) {
      return (
        <div className="ov_right_csr">
          <div className="ov_details">
            <Avatar
              src={user?.userInfo?.image}
              className="ov_img_csr"
              variant="rounded"
            />
            <div className="ov_details_content">
            {stateData?.userInfo?.userSubscriptionPurchase && 
            <div className="s-10 mt-2" style={{padding:'2px 8px', borderRadius:'12px',
            color:'#000',
             backgroundColor:
               stateData?.userInfo?.userSubscriptionPurchase?.title==='Standard Plan' ? '#C77EFF' : '#CDFF7E'
            }}>
              {stateData?.userInfo?.userSubscriptionPurchase?.title}
            </div>}
              {user?.delivery?.location?.name && (
                <p className="ovr_name_csr">{user?.delivery?.location.name}</p>
              )}
              {stateData.delivery?.location && (
                <span className="ovr_phone_csr mt-2">
                  {stateData?.delivery?.location?.phone}
                </span>
              )}
            </div>

            <Box
                  className="blacklist_button_csr ml-auto"
                  display={{md:'flex',lg:'none'}}
                  minWidth='180px'
                  onClick={() => blackList()}
                >
                  Blacklist User
                </Box>
          </div>

          {user?.delivery?.location?.email && (
            <span className="ov_user_details_text mb-2">
              {user?.delivery?.location?.email}
            </span>
          )}
          <p className="ovr_blue">Date</p>
          <p className="ov_user_details_text mb-2">
            {formatDateTimeNext(new Date(stateData?.createdAt))}
          </p>

          {stateData?.delivery?.location &&
            (stateData?.delivery?.mapUrl ||
              (stateData.delivery.location.latitude &&
                stateData.delivery.location.longitude)) && (
              <span className="ovr_blue d-flex flex-row  align-items-center gap-2">
                Delivery Address{"   "}
                {stateData.locationStatus && (
                  <div style={{ width: "1.5rem", height: "1.59rem", color:'#fff' }}>
                    {getDeliveryLocationStatusIconCsr(
                      stateData?.locationStatus,
                    )}
                  </div>
                )}
              </span>
            )}
          {/* <StatusIcon status="Not Verified" />
            <StatusIcon status="Hold" />
            <StatusIcon status="Verified" /> */}
          {/* <span>
              {stateData.delivery &&
                stateData.delivery.location &&
                stateData.delivery.location?.address}
            </span> */}

          {stateData.delivery && stateData?.delivery?.location?.mapUrl && (
            <div className="d-flex align-items-center text-white">
              <span>
                {stateData?.delivery?.location?.mapUrl.slice(0, 25 - 3) + "..."}
              </span>

              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  copyMapUrl(stateData?.delivery?.location?.mapUrl);
                }}
                sx={{ color: "#FFF" }}
              >
                <Copy sx={{ color: "#FFF" }} />
              </IconButton>
            </div>
          )}
          {stateData.delivery &&
            !stateData?.delivery?.location?.mapUrl &&
            stateData.delivery.location?.latitude &&
            stateData.delivery.location?.longitude && (
              <div className="d-flex align-items-center">
                <span className="ov_user_details_text ">
                  {parseFloat(stateData.delivery.location.latitude).toFixed(3)}
                  ...
                </span>
                <span className="ml-2 mr-1 ov_user_details_text ">
                  {parseFloat(stateData.delivery.location.longitude).toFixed(3)}
                  ...
                </span>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    copyGoogleLocation();
                  }}
                  sx={{ color: "#FFF" }}
                >
                  <Copy sx={{ color: "#FFF" }} />
                </IconButton>
              </div>
            )}
          {stateData?.payment && (
            <span className="ovr_blue mb-1">Payment Method</span>
          )}
          <p className="text-white">{stateData?.payment?.method}</p>
          {stateData.note && (
            <div className="ovr_note_csr">
              <p className="ovr_blue">Order note</p>
              <p className="note">{stateData?.note}</p>
            </div>
          )}
          {stateData.dispatchNote && (
            <div className="ovr_note_csr">
              <p className="ovr_blue">Order Dispatch note</p>
              <p className="note">{stateData?.dispatchNote}</p>
            </div>
          )}

          {userDetails &&
          userDetails?.isSystemUser &&
          userDetails?.user?.totalCompletedOrders ? (
            <>
              <p className="ovr_blue">Total completed Orders</p>
              <p className="ov_user_details_text mb-2">
                {userDetails?.user?.totalCompletedOrders}
              </p>
            </>
          ) : (
            ""
          )}
          {userDetails &&
          userDetails?.isSystemUser &&
          userDetails?.user?.totalFailedOrders ? (
            <>
              <p className="ovr_blue">Total Failed Orders</p>
              <p className="ov_user_details_text mb-2">
                {userDetails?.user?.totalFailedOrders}
              </p>
            </>
          ) : (
            ""
          )}

          {userDetails &&
          userDetails?.isSystemUser &&
          userDetails?.user?.totalOrders &&
          userDetails?.user?.totalOrders === 1 ? (
            <>
              <p className="ov_user_details_text mb-2">
                <div
                  style={{
                    border: "1px solid #09d402",
                    padding: "0.2rem 1.5rem",
                    color: "#09d402",
                    fontWeight: "600",
                    borderRadius: "3px",
                    width: "fit-content",
                  }}
                >
                  New
                </div>
              </p>
            </>
          ) : (
            ""
          )}

          {showBanButton ? (
            blackListing_loading ? (
              <>
                <p className="ov_user_details_text mb-2 ">
                  <div className="blacklist_button_csr">
                    <CircularProgress sx={{ color: "red" }} size={16} />
                  </div>
                </p>
              </>
            ) : isUserBanned ? (
              <p className="ov_user_details_text mb-2">
                <div
                  className="blacklist_button_csr"
                  onClick={() => blackList()}
                >
                  Remove Blacklist
                </div>
              </p>
            ) : (
              <p className="ov_user_details_text mb-2">
                <div
                style={{width:'9rem'}}
                  className="blacklist_button_csr"
                  onClick={() => blackList()}
                >
                  Blacklist User
                </div>
              </p>
            )
          ) : (
            <></>
          )}

          <p className="d-flex align-items-center gap-2 mt-2 text-white">
            Copy Details{" "}
            <IconButton
              onClick={(e) => copyUserDetails(e, stateData, setValues)}
              sx={{ color: "#FFF" }}
            >
              <Copy sx={{ color: "#FFF" }} />
            </IconButton>
          </p>
          
          <p className="d-flex align-items-center gap-2 mt-2 text-white">
            Copy Tracking Url{" "}
            <IconButton
              onClick={(e) => copyTrackingUrlDetails(e, stateData, setValues)}
              sx={{ color: "#FFF" }}
            >
              <Copy sx={{ color: "#FFF" }} />
            </IconButton>
          </p>
        </div>
      );
    }
  };

  const assignDriverFn = () => {
    if (isTextEmpty(assignTo)) {
      return;
    }
    setValues((x) => ({ ...x, assign_loading: true }));
    if (isTextEmpty(assignTo)) {
      setValues((x) => ({
        ...x,
        errorPosition: "assignTo",
        errorText: "Assign error",
      }));
      return;
    }
    setValues((x) => ({ ...x, errorPosition: "", errorText: "" }));
    var send = {
      orderId: stateData._id,
      riderId: assignTo,
      distance: parseFloat(distance),
    };
    POSTAPI_CSR_ADMIN("/order/rider/assign", send).then((res) => {
      if (res.success) {
        POSTAPI_CSR_ADMIN("/order/one", { orderId: id }).then((res) => {
          if (res.success) {
            setValues((x) => ({
              ...x,
              stateData: res.order,
              data_loading: false,
            }));
            setFetchOrder(true);
          } else if (res.error)
            setValues((x) => ({
              ...x,
              errorPosition: "apiErr",
              errorText: res.error,
              data_loading: false,
            }));
        });
        setValues((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Rider updated",
          assign_loading: false,
          changeDriverModal: false,
          stateData: res.order[0],
          distance: "",
        }));
      } else if (res.error)
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          assign_loading: false,
        }));
    });
  };

  const EditDriverClickedFn = () => {
    setValues((x) => ({
      ...x,
      changeDriverModal: true,
      assignTo: stateData.delivery.riderInfo.riderId,
    }));
  };

  const handleChangeStatusByAdmin = (newStatus) => {
    setValues((x) => ({ ...x, changeState_loading: true }));
    POSTAPI_CSR_ADMIN("/order/status/change", {
      orderId: stateData._id,
      newStatus: newStatus,
    }).then((res) => {
      if (res.success) {
        var newStateData = { ...stateData };
        newStateData.status = newStatus;
        setValues((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Status updated",
          stateData: newStateData,
          anchorElChangeStatus: null,
          changeState_loading: false,
          showStatusCompleteModal: false,
          showStatusFailedModal: false,
        }));
        setFetchOrder(true);
        setFetchDraft(true);
        fetchViewOrder();
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          changeState_loading: false,
          anchorElChangeStatus: null,
        }));
      }
    });
  };
  const handleChangePriorityByAdmin = (newStatus) => {
    setValues((x) => ({ ...x, changePriority_loading: true }));
    POSTAPI_CSR_ADMIN("/order/admin/priority/change", {
      orderId: stateData._id,
      priorityLevel: newStatus,
    }).then((res) => {
      if (res.success) {
        var newStateData = { ...stateData };
        newStateData.priority = newStatus;
        setValues((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Priority updated",
          stateData: newStateData,
          anchorElChangePriority: null,
          changePriority_loading: false,
          showStatusCompleteModal: false,
          showStatusFailedModal: false,
        }));
        setFetchOrder(true);
        setFetchDraft(true);
        fetchViewOrder();
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          changePriority_loading: false,
          anchorElChangePriority: null,
        }));
      }
    });
  };

  const handleChangeStatusCompleted = (newStatus) => {
    setValues((x) => ({ ...x, changeState_loading: true }));
    POSTAPI_CSR_ADMIN("/order/status/change", {
      orderId: stateData._id,
      newStatus: newStatus,
      paymentInfo: [
        {
          method: "Qr",
          amount: parseFloat(qrAmount) || 0,
        },
        {
          method: "Cash",
          amount: parseFloat(cashAmount) || 0,
        },
      ],
      unpaidAmount: parseFloat(unpaidAmount) || 0,
      tipAmount: parseFloat(tipAmount) || 0,
    }).then((res) => {
      if (res.success) {
        var newStateData = { ...stateData };
        newStateData.status = newStatus;
        setValues((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Status updated",
          stateData: newStateData,
          anchorElChangeStatus: null,
          changeState_loading: false,
          showStatusCompleteModal: false,
          showStatusFailedModal: false,
        }));
        setFetchOrder(true);
        fetchViewOrder();
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          changeState_loading: false,
          anchorElChangeStatus: null,
        }));
      }
    });
  };

  const handleChangeStatusFailed = () => {
    if (!note || /^\s*$/.test(note)) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Please provide reason for cancellation",
      }));
      return;
    }
    let newStatus = ORDER_STATUS.FAILED;
    setValues((x) => ({ ...x, changeState_loading: true }));
    POSTAPI_CSR_ADMIN("/order/admin/cancel", {
      orderId: stateData._id,
      failReason: note,
    }).then((res) => {
      if (res.success) {
        var newStateData = { ...stateData };
        newStateData.status = newStatus;
        setValues((x) => ({
          ...x,
          errorPosition: "success",
          errorText: "Status updated",
          stateData: newStateData,
          anchorElChangeStatus: null,
          changeState_loading: false,
          showStatusCompleteModal: false,
          showStatusFailedModal: false,
        }));
        setFetchOrder(true);
        fetchViewOrder();
      } else if (res.error) {
        setValues((x) => ({
          ...x,
          errorPosition: "apiErr",
          errorText: res.error,
          changeState_loading: false,
          anchorElChangeStatus: null,
        }));
      }
    });
  };

  const submitCompleteOrder = () => {
    let a = parseInt(qrAmount) || 0;
    let b = parseInt(cashAmount) || 0;
    let c = parseInt(unpaidAmount) || 0;
    const amountTotal = a + b + c;

    if (parseInt(amountTotal) <= 0) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Amount can't be less than 0",
      }));
      return;
    }
    if (parseInt(amountTotal) !== parseInt(stateData.payment.total)) {
      setValues((x) => ({
        ...x,
        errorPosition: "apiErr",
        errorText: "Please provide total amount",
      }));
      return;
    }

    handleChangeStatusCompleted(ORDER_STATUS.COMPLETED);
  };

  const FinishCookingPrint = (props) => {
    return (
      <>
        <div>
          <div
            id="printId"
            className="printNeo"
            style={{ fontFamily: "Arial" }}
          >
            <div
              className="printNeoBody d-flex flex-column"
              style={{ fontFamily: "Arial", fontWeight: "400" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    marginBottom: "5px",
                  }}
                >
                  NOVA FOODS PVT. LTD
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  BAKHUNDOLE, LALITPUR
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  PAN NO. 610284807
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "5px",
                  }}
                >
                  ABBREVIATED TAX INVOICE
                </span>
              </div>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: "5px",
                }}
              >
                <span>Order No. : #{props.data.trackingNumber}</span>
                <span> {formatDate(props.data.createdAt)}</span>
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                  fontSize: "12px",
                  lineHeight: "14px",
                  marginBottom: "5px",
                }}
              >
                <span>
                  Bill To :{" "}
                  {props.data?.isAdminCreated &&
                  !props.data?.delivery?.deliveryPartner?.isDefaultPartner
                    ? props.data?.delivery?.deliveryPartner?.name
                    : ""}
                  {props.data?.isAdminCreated &&
                  props.data?.delivery?.location?.name
                    ? props.data?.delivery?.location?.name
                    : ""}
                  {!props.data?.isAdminCreated &&
                  props.data?.delivery?.location?.name
                    ? props.data?.delivery?.location?.name
                    : ""}
                </span>
                <span>{formatTime(props.data.createdAt)}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                {props.data?.delivery?.location?.phone ? (
                  <>
                    <span
                      style={{
                        fontSize: "12px",
                        lineHeight: "14px",
                      }}
                    >
                      Phone number : {props.data?.delivery?.location?.phone}
                    </span>
                  </>
                ) : (
                  ""
                )}
                <span
                  style={{
                    fontSize: "12px",
                    lineHeight: "14px",
                  }}
                >
                  Payment Method : {props.data.payment.method}
                </span>
              </div>

              <div
                style={{
                  margin: "10px 0",
                  borderTop: "2px solid #000",
                }}
              />
              <div>
                {props &&
                  props.data &&
                  props.data.foods &&
                  props.data.foods.length > 0 &&
                  props.data.foods.map((each, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "12px",
                          lineHeight: "14px",
                          marginBottom: "15px",
                        }}
                      >
                        <div>
                          <span>{each.quantity}</span>
                          <span style={{ marginLeft: "5px" }}>
                            {each.name}{" "}
                          </span>
                          {each.modifiers && each.modifiers.length > 0 ? (
                            <span
                              style={{
                                width: "fit-content",
                                marginLeft: "auto",
                                float: "right",
                              }}
                            >
                              Rs.{each?.unitPrice}
                            </span>
                          ) : (
                            ""
                          )}

                          {!each?.modifiers || each.modifiers.length === 0 ? (
                            <span
                              style={{
                                width: "fit-content",
                                marginLeft: "auto",
                                float: "right",
                              }}
                            >
                              Rs.{each.price}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        {each?.modifiers && each?.modifiers.length > 0 ? (
                          <>
                            {each.modifiers.map((item, i) =>
                              item.options.map((x, a) => (
                                <div
                                  key={a}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginLeft: "10px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <span>{x.name}</span>
                                  <span>Rs. {x.price}</span>
                                </div>
                              )),
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {each?.spiceLevel && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginLeft: "10px",
                              marginTop: "5px",
                            }}
                          >
                            <span>Spice level</span>
                            <span>{each.spiceLevel}</span>
                          </div>
                        )}

                        {each?.modifiers && each?.modifiers.length > 0 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "5px",
                            }}
                          >
                            <span>Rs. {each.price}</span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
              </div>
              <div
                style={{
                  margin: "10px 0",
                  borderTop: "2px solid #000",
                }}
              />
              {props.data &&
                props.data.payment &&
                props.data.payment.subTotal && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                      lineHeight: "14px",
                      marginBottom: "3px",
                    }}
                  >
                    <span>Subtotal</span>
                    <span>Rs. {props.data.payment.subTotal}</span>
                  </div>
                )}

              {props.data &&
              props.data.payment &&
              props.data.payment?.deliveryCharge &&
              props.data.payment?.deliveryCharge > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "12px",
                    lineHeight: "14px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Delivery Charge</span>
                  <span>Rs. {props.data.payment.deliveryCharge}</span>
                </div>
              ) : (
                ""
              )}

              {props.data &&
              props.data.payment &&
              props.data.payment?.promoDiscount ? (
                <>
                  {props.data.payment?.promoDiscount > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "3px",
                      }}
                    >
                      <span>Promo Discount</span>
                      <span>- Rs. {props.data.payment.promoDiscount}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : null}

              {props.data &&
              props.data.payment &&
              props.data.payment.taxes &&
              props.data.payment.taxes.length > 0
                ? props.data.payment.taxes.map((each, i) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "12px",
                        lineHeight: "14px",
                        marginBottom: "3px",
                      }}
                      key={i}
                    >
                      <span>{each.name}</span>
                      <span>
                        {each.type === "value" && "Rs."} {each.amount}{" "}
                        {each.type === "percent" && "%"}
                      </span>
                    </div>
                  ))
                : ""}
              {props.data &&
              props.data.payment &&
              props.data.payment?.paidAmount &&
              props.data.payment?.paidAmount > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Paid Amount</span>
                  <span>Rs. {props.data.payment?.paidAmount}</span>
                </div>
              ) : (
                ""
              )}
              {props.data && props.data.payment && props.data.payment.total && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Total</span>
                  <span>Rs. {props.data.payment.total}</span>
                </div>
              )}
              {props.data &&
              props.data.payment &&
              props.data.payment?.remainingAmount &&
              props.data.payment?.remainingAmount > 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "700",
                    fontSize: "14px",
                    lineHeight: "18px",
                    marginBottom: "3px",
                  }}
                >
                  <span>Remaining Amount</span>
                  <span>Rs. {props.data.payment?.remainingAmount}</span>
                </div>
              ) : (
                ""
              )}
              <div
                style={{
                  margin: "10px 0",
                  borderTop: "1px solid #000",
                }}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p style={{ fontSize: "10px", lineHeight: "12px" }}>
                  THANK YOU FOR YOUR ORDER
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handlePrintED = (props) => {
    var prtContent = document.getElementById(`printId`);
    if (!prtContent) return;
    var printWindow = window.open(
      "",
      "",
      "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0",
    );
    printWindow.document.write(prtContent.innerHTML);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  if (data_loading) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <CircularProgress style={{ color: "#00F0FF" }} />
      </div>
    );
  }

  if (id && stateData === null) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        No order details found
      </div>
    );
  }

  return (
    <>
      {id && stateData && Object.keys(stateData).length > 0 && (
        <ThemeProvider theme={csrTheme}>
          <div className="csr_view_details_container">
           
            <div className="csr_create_order_header d-flex justify-content-between align-content-center">
              <Box
                className=" d-flex justify-content-between align-content-center"
                style={{
                  height: "2.3rem",
                }}
                width={{md:'320px', lg:'32rem'}}
              >
                 <IconButton className="text-white ipadPort mr-3" onClick={e=>setView(false)}>
                    <ArrowLeft />
                </IconButton>
                <div className="csr_create_order_header_titles" style={{ minWidth:' fit-content'}}>
                  {ShowOriginLogo(stateData?.origin)}
                  &nbsp; #ORD-{stateData?.trackingNumber}
                </div>
                <div className="change_status" style={{ marginLeft: 10}}>
                  <Button
                    className="float-right mb-4"
                    aria-controls={openChangeStatus ? "user-menu" : undefined}
                    aria-expanded={openChangeStatus ? "true" : undefined}
                    style={{
                      width: "11.2rem",
                      height: "2.3rem",
                      border: "1px solid #545356",
                      color: "white",
                      fontSize: "0.9rem",
                      fontFamily: "Blender Pro thin",
                    }}
                    aria-haspopup="true"
                    endIcon={!changeState_loading && <CaretDown />}
                    onClick={(e) =>
                      setValues((x) => ({
                        ...x,
                        anchorElChangeStatus: e.currentTarget,
                      }))
                    }
                  >
                    {changeState_loading ? (
                      <CircularProgress
                        style={{ color: "#FFFFFF" }}
                        size={18}
                      />
                    ) : (
                      "Change Status"
                    )}
                  </Button>
                  <Menu
                    id="user-menu"
                    MenuListProps={{
                      "aria-labelledby": "user-button",
                    }}
                    anchorEl={anchorElChangeStatus}
                    open={openChangeStatus}
                    onClose={() =>
                      setValues((x) => ({ ...x, anchorElChangeStatus: null }))
                    }
                    PaperProps={{
                      style: {
                        width: "11.2rem",
                        backgroundColor: "black",
                        border: "1px solid #545356",
                        color: "white",
                        fontFamily: "Blender Pro thin",
                      },
                    }}
                  >
                    {!stateData.isAdminCreated &&
                    stateData.status === ORDER_STATUS.DRAFT ? (
                      <MenuItem
                        onClick={() => {
                          handleChangeStatusByAdmin(ORDER_STATUS.QUEUE);
                        }}
                        sx={{
                          fontFamily: "Blender Pro thin",
                        }}
                      >
                        Queue
                      </MenuItem>
                    ) : (
                      ""
                    )}
                    <MenuItem
                      onClick={() => {
                        // handleChangeStatusByAdmin(ORDER_STATUS.COMPLETED);
                        setValues((x) => ({
                          ...x,
                          showStatusCompleteModal: true,
                          anchorElChangeStatus: null,
                        }));
                      }}
                      sx={{
                        fontFamily: "Blender Pro thin",
                      }}
                    >
                      Completed
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        // handleChangeStatusByAdmin(ORDER_STATUS.FAILED);
                        setValues((x) => ({
                          ...x,
                          showStatusFailedModal: true,
                          anchorElChangeStatus: null,
                        }));
                      }}
                      sx={{
                        fontFamily: "Blender Pro thin",
                      }}
                    >
                      Failed
                    </MenuItem>
                  </Menu>
                </div>
                <div className="change_status" style={{ marginInline: 10}}>
                  <Button
                    className="float-right mb-4"
                    aria-controls={openChangePriority ? "user-menu-priority" : undefined}
                    aria-expanded={openChangePriority ? "true" : undefined}
                    style={{
                      width: "11.2rem",
                      height: "2.3rem",
                      border: "1px solid #545356",
                      color: "white",
                      fontSize: "0.9rem",
                      fontFamily: "Blender Pro thin",
                    }}
                    aria-haspopup="true"
                    endIcon={!changePriority_loading && <CaretDown />}
                    onClick={(e) =>
                      setValues((x) => ({
                        ...x,
                        anchorElChangePriority: e.currentTarget,
                      }))
                    }
                  >
                    {changePriority_loading ? (
                      <CircularProgress
                        style={{ color: "#FFFFFF" }}
                        size={18}
                      />
                    ) : (
                      "Change Priority"
                    )}
                  </Button>
                  <Menu
                    id="user-menu-priority"
                    MenuListProps={{
                      "aria-labelledby": "user-button",
                    }}
                    anchorEl={anchorElChangePriority}
                    open={openChangePriority}
                    onClose={() =>
                      setValues((x) => ({ ...x, anchorElChangePriority: null }))
                    }
                    PaperProps={{
                      style: {
                        width: "11.2rem",
                        backgroundColor: "black",
                        border: "1px solid #545356",
                        color: "white",
                        fontFamily: "Blender Pro thin",
                      },
                    }}
                  >
                      <MenuItem
                        onClick={() => {
                          handleChangePriorityByAdmin(PRIORITY_LEVEL.HIGH);
                        }}
                        sx={{
                          fontFamily: "Blender Pro thin",
                        }}
                      >
                        High
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleChangePriorityByAdmin(PRIORITY_LEVEL.MEDIUM);
                        }}
                        sx={{
                          fontFamily: "Blender Pro thin",
                        }}
                      >
                        medium
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleChangePriorityByAdmin(PRIORITY_LEVEL.LOW);
                        }}
                        sx={{
                          fontFamily: "Blender Pro thin",
                        }}
                      >
                        Low
                      </MenuItem>
                  </Menu>
                </div>
              </Box>

              <Box 
              display={{md:'none',lg:'flex'}}
              className="ml-auto">
                {(
                  stateData.status === "Not Assigned" ||
                  stateData.status === "Pending") 
                  && (
                  <Button
                    onClick={() => handlePrintED()}
                    style={{
                      width: "6rem",
                      height: "2.3rem",
                      border: "1px solid #545356",
                      color: "white",
                      fontSize: "0.9rem",
                      fontFamily: "Blender Pro thin",
                    }}
                  >
                    Print Bill
                  </Button>
                )}
              </Box>

              <div
                className="ml-auto"
                style={{ transform: "translateX(30px)" }}
              >
                <IconButton
                  onClick={() => {
                    setShowCreateOrder(true);
                    setShowEditOrder(false);
                    setShowViewOrder(false);
                    setCurrentId("");
                  }}
                >
                  <X color="#FFFFFF" />
                </IconButton>
              </div>
            </div>
            <Box 
            sx={{
              mb:'10px',
              alignItems:'center', justifyContent:'space-between'
            }}
            width={{
              md:'400px',
            }}
            className='stat_btn_2'
            >
                  <Button
                    style={{
                      width: "110px",
                      paddingInline: "10px",
                      outline: "0",
                    }}
                    className={statusButtonClass(stateData.status)[0]}
                  >
                    {statusButtonClass(stateData.status)[1]}
                  </Button>
                <Box 
              className="ml-3">
                {(
                  stateData.status === "Not Assigned" ||
                  stateData.status === "Pending") 
                  && (
                  <Button
                    onClick={() => handlePrintED()}
                    style={{
                      width: "6rem",
                      height: "2.3rem",
                      border: "1px solid #545356",
                      color: "white",
                      fontSize: "0.9rem",
                      fontFamily: "Blender Pro thin",
                    }}
                  >
                    Print Bill
                  </Button>
                )}
              </Box>
            </Box>
            {/* failed reason */}
            {stateData.status === "Failed" && (
              <div
                className="p-2 mt-3 mb-2 ml-3"
                style={{
                  border: "1px solid rgb(0, 240, 255)",
                  borderRadius: "1px",
                  color: "#fff",
                  width: "fit-content",
                  fontFamily: "Blender Pro thin",
                }}
              >
                Failed reason: {stateData?.delivery?.failReason}
              </div>
            )}
            <Box className="flex-column user_deets_div_1"
            display={{md:'flex',lg:'none'}}
            >
                  <Box className="csr_div_user_details_child_second"
                  width={{md:'100%',lg:'15.75rem'}}>
                    {UserDetails()}
                </Box>
                <div className="mt-4" />
                {stateData.status === "Completed" && (
                  <>
                    <div className="ml-3">
                      <InputLabelCsr
                        label="Driver Payment Status"
                        varName={driverPaymentStatus}
                      />
                      <div style={{ paddingLeft: "0.4rem" }}>
                        <RadioGroup
                          aria-label="location-status"
                          name="location-status"
                          value={driverPaymentStatus}
                          onChange={(e) => {
                            setValues((x) => ({
                              ...x,
                              driverPaymentStatus: e.target.value,
                            }));
                            POSTAPI_CSR_ADMIN(
                              "/order/admin/rider/paid/status",
                              {
                                orderId: stateData?._id,
                                riderPaidStatus: e.target.value,
                              },
                            ).then((res) => {
                              if (res.success) {
                                fetchViewOrder();
                                setFetchOrder(true);
                                setValues((x) => ({
                                  ...x,
                                  errorPosition: "success",
                                  errorText:
                                    "Driver Payment Status successfully updated.",
                                }));
                              } else if (res.error)
                                setValues((x) => ({
                                  ...x,
                                  errorPosition: "apiErr",
                                  errorText: res.error,
                                }));
                            });
                          }}
                          row={true}
                        >
                          <CustomFormControlLabel
                            value="Hold"
                            control={<CustomRadio />}
                            label="Hold"
                          />
                          <CustomFormControlLabel
                            value="Completed"
                            control={<CustomRadio />}
                            label="Completed"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </>
                )}
              </Box>
            <div className="csr_div_user_details ">
              <div className="csr_div_user_details_child_first ">
                {/* delivery assign */}

                {stateData.status !== ORDER_STATUS.DRAFT &&
                  stateData.status !== ORDER_STATUS.QUEUE &&
                  stateData.status !== ORDER_STATUS.COOKING && (
                    <>
                      {stateData.delivery.riderInfo ? null : (
                        <div className="del_assign_csr mb-4">
                          <p className="w3">Delivery Assign</p>
                          <InputLabelCsr
                            label="Assign to"
                            varName={assignTo}
                            errorPosition={errorPosition}
                            errorText={errorText}
                          />
                          {ReactSelectDropCsr(
                            assignTo,
                            "assignTo",
                            setValues,
                            riders,
                          )}
                          <div className="fs-6 fw-semibold d-flex flex-column justify-content-center gap-1">
                            <div className="mr-2">Total distance (km)</div>
                            <TextField
                              type="number"
                              sx={{
                                border: "1px solid #545356",
                              }}
                              placeholder=""
                              value={distance}
                              onChange={(e) => {
                                setValues((x) => ({
                                  ...x,
                                  distance: e.target.value,
                                }));
                              }}
                              InputProps={{
                                style: {
                                  outline: "none",
                                  borderRadius: "0px",
                                  height: "30px",
                                  color: "white",
                                  fontFamily: "Blender Pro thin",
                                  "&:focus": {
                                    outline: "none !important",
                                  },
                                },
                              }}
                            />
                          </div>

                          {assign_loading ? (
                            <div className="csr_button">
                              <CircularProgress
                                sx={{ color: "#8943EF" }}
                                size={17}
                              />{" "}
                            </div>
                          ) : (
                            <div
                              className="csr_button"
                              onClick={() => assignDriverFn()}
                            >
                              Assign
                            </div>
                          )}
                        </div>
                      )}

                      {/* show rider */}
                      {stateData &&
                        stateData.delivery &&
                        stateData.delivery.riderInfo &&
                        stateData.delivery.riderInfo.name && (
                          <div className="del_assign_view_csr cursor_pointer mb-4">
                            <div
                              className="d-flex flex-column"
                              onClick={() =>
                                setValues((x) => ({
                                  ...x,
                                  changeDriverModal: true,
                                  assignTo:
                                    stateData.delivery.riderInfo.riderId,
                                  distance: parseFloat(
                                    stateData.delivery?.location?.distance,
                                  ),
                                }))
                              }
                            >
                              <div
                                className="d-flex justify-content-between align-items-center"
                                style={{
                                  height: "fit-content",
                                  fontSize: "1.3rem",
                                  width: "100% !important",
                                  padding: "0",
                                }}
                              >
                                <span className="s-20 c4">
                                  {stateData.delivery.riderInfo.name}
                                </span>
                                <div>
                                  <MUIMenuTwo
                                    EditFn={EditDriverClickedFn}
                                    // DeleteFn={null}
                                  />
                                </div>
                              </div>
                              <p
                                className="s1 c4"
                                style={{
                                  marginTop: "4px",
                                  marginBottom: "16px",
                                }}
                              >
                                {stateData.delivery.riderInfo.phone}
                              </p>
                              <div
                                className="d-flex justify-content-between"
                                style={{ color: "#00F0FF" }}
                              >
                                <div
                                  className="d-flex flex-column"
                                  style={{ gap: "6px" }}
                                >
                                  <p className="mb-0 mt-0 s1">Total Distance</p>
                                  <p className="mb-0 mt-0 s1 c4">
                                    {stateData.delivery?.location?.distance} km
                                  </p>
                                </div>
                              </div>
                            </div>
                            <CsrModal
                              open={changeDriverModal}
                              onClose={() =>
                                setValues((x) => ({
                                  ...x,
                                  changeDriverModal: false,
                                }))
                              }
                            >
                              <Box
                                style={style}
                                sx={{
                                  width: "400px",
                                  padding: "1rem !important",
                                }}
                              >
                                <div className="d-flex justify-content-between">
                                  <p className="w3 s3 mr-4 csr_create_order_header_titles">
                                    Delivery assign
                                  </p>
                                  <IconButton
                                    onClick={() =>
                                      setValues((x) => ({
                                        ...x,
                                        changeDriverModal: false,
                                      }))
                                    }
                                  >
                                    <X color="#FFF" size={30} />
                                  </IconButton>
                                </div>
                                <InputLabelCsr
                                  label="Assign To"
                                  varName={assignTo}
                                  errorPosition={errorPosition}
                                  errorText={errorText}
                                />
                                {ReactSelectDropCsr(
                                  assignTo,
                                  "assignTo",
                                  setValues,
                                  riders,
                                )}

                                <div className="fs-6 fw-semibold d-flex flex-column justify-content-center gap-1">
                                  <div className="mr-2">
                                    Total distance (km)
                                  </div>
                                  <TextField
                                    type="number"
                                    sx={{
                                      border: "1px solid #545356",
                                    }}
                                    placeholder=""
                                    value={distance}
                                    onChange={(e) => {
                                      setValues((x) => ({
                                        ...x,
                                        distance: e.target.value,
                                      }));
                                    }}
                                    InputProps={{
                                      style: {
                                        outline: "none",
                                        borderRadius: "0px",
                                        height: "30px",
                                        color: "white",
                                        fontFamily: "Blender Pro thin",
                                        "&:focus": {
                                          outline: "none !important",
                                        },
                                      },
                                    }}
                                  />
                                </div>

                                {assign_loading ? (
                                  <div className="csr_button">
                                    <CircularProgress
                                      sx={{ color: "#8943EF" }}
                                      size={17}
                                    />{" "}
                                  </div>
                                ) : (
                                  <div
                                    className="csr_button"
                                    onClick={() => assignDriverFn()}
                                  >
                                    Assign
                                  </div>
                                )}
                              </Box>
                            </CsrModal>
                          </div>
                        )}
                    </>
                  )}

                {/* table */}
                <div className="d-flex w-100 mt-4">
                  <div className="ov_left">
                 <div style={{
                  display:'flex',
                  justifyContent:'space-between',
                  alignItems:'center'
                 }}>
                 {<div style={{ width:'100%' }}>
                    {getPriorityLevelTextCSR(priority)}
                  </div>}
                    <Button
                      variant="contained"
                      endIcon={<Pen sx={{ color: "red" }} />}
                      className="float-right mb-3 w-5 ml-auto"
                      sx={{
                        backgroundColor: "black",
                        border: "1px solid red",
                        color: "red",
                        width: "100px",
                        fontFamily: "Blender Pro thin",
                        fontSize: "1.2rem",
                        fontWeight: "600",
                        height: "2.5rem",
                        "&:hover": {
                          backgroundColor: "black",
                        },
                      }}
                      onClick={(e) => {
                        setRefetchEdit && setRefetchEdit(true);
                        setShowViewOrder(false);
                        setCurrentId(id);
                        setShowEditOrder(true);
                        setShowCreateOrder(false);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                    {OrderViewTableCsr(stateData, stateData.foods)}
                  </div>
                </div>
              </div>
              <Box display={{md:'none',lg:'flex'}}  className="flex-column user_deets_div_2">
              <Box className="csr_div_user_details_child_second"
                  width={{md:'100%',lg:'15.75rem'}}>
                    {UserDetails()}
                </Box>
                <div className="mt-4" />
                {stateData.status === "Completed" && (
                  <>
                    <div className="ml-3">
                      <InputLabelCsr
                        label="Driver Payment Status"
                        varName={driverPaymentStatus}
                      />
                      <div style={{ paddingLeft: "0.4rem" }}>
                        <RadioGroup
                          aria-label="location-status"
                          name="location-status"
                          value={driverPaymentStatus}
                          onChange={(e) => {
                            setValues((x) => ({
                              ...x,
                              driverPaymentStatus: e.target.value,
                            }));
                            POSTAPI_CSR_ADMIN(
                              "/order/admin/rider/paid/status",
                              {
                                orderId: stateData?._id,
                                riderPaidStatus: e.target.value,
                              },
                            ).then((res) => {
                              if (res.success) {
                                fetchViewOrder();
                                setFetchOrder(true);
                                setValues((x) => ({
                                  ...x,
                                  errorPosition: "success",
                                  errorText:
                                    "Driver Payment Status successfully updated.",
                                }));
                              } else if (res.error)
                                setValues((x) => ({
                                  ...x,
                                  errorPosition: "apiErr",
                                  errorText: res.error,
                                }));
                            });
                          }}
                          row={true}
                        >
                          <CustomFormControlLabel
                            value="Hold"
                            control={<CustomRadio />}
                            label="Hold"
                          />
                          <CustomFormControlLabel
                            value="Completed"
                            control={<CustomRadio />}
                            label="Completed"
                          />
                        </RadioGroup>
                      </div>
                    </div>
                  </>
                )}
              </Box>
            </div>
            <CsrModal
              open={showStatusCompleteModal}
              onClose={() => handleCloseModal()}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="csr_create_order_header_titles">
                    Complete Order
                  </span>
                  <IconButton onClick={() => handleCloseModal()}>
                    <X color="#FFFFFF" />
                  </IconButton>
                </div>

                <div className="d-flex flex-column mt-2">
                  <InputLabelCsr label="QR amount" varName={qrAmount} />
                  <TextField
                    type="number"
                    value={qrAmount || ""}
                    sx={{
                      border: "1px solid #545356",
                    }}
                    className="custom-text-field"
                    onChange={(e) => {
                      setValues((x) => ({
                        ...x,
                        qrAmount: e.target.value,
                      }));
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        outline: "none !important",
                        color: "white",
                        height: "2.5rem",
                        fontFamily: "Blender Pro thin",

                        "&:focus": {
                          outline: "none !important",
                        },
                      },
                    }}
                  />
                  <div className="mt-2" />
                  <InputLabelCsr label="Cash Amount" />
                  <TextField
                    type="number"
                    sx={{
                      border: "1px solid #545356",
                    }}
                    value={cashAmount || ""}
                    className="custom-text-field"
                    onChange={(e) => {
                      setValues((x) => ({
                        ...x,
                        cashAmount: e.target.value,
                      }));
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        outline: "none !important",
                        color: "white",
                        height: "2.5rem",
                        fontFamily: "Blender Pro thin",

                        "&:focus": {
                          outlineColor: "none !important",
                        },
                      },
                    }}
                  />
                  <div className="mt-2" />
                  <InputLabelCsr label="Unpaid Amount" />
                  <TextField
                    type="number"
                    sx={{
                      border: "1px solid #545356",
                    }}
                    value={unpaidAmount || ""}
                    className="custom-text-field"
                    onChange={(e) => {
                      setValues((x) => ({
                        ...x,
                        unpaidAmount: e.target.value,
                      }));
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        outline: "none !important",
                        color: "white",
                        height: "2.5rem",
                        fontFamily: "Blender Pro thin",
                        "&:focus": {
                          outlineColor: "none !important",
                        },
                      },
                    }}
                  />
                  <div className="mt-2" />
                  <InputLabelCsr label="Tip Amount" />
                  <TextField
                    type="number"
                    sx={{
                      border: "1px solid #545356",
                    }}
                    value={tipAmount || ""}
                    className="custom-text-field"
                    onChange={(e) => {
                      setValues((x) => ({
                        ...x,
                        tipAmount: e.target.value,
                      }));
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "0px",
                        outline: "none !important",
                        color: "white",
                        height: "2.5rem",
                        fontFamily: "Blender Pro thin",
                        "&:focus": {
                          outlineColor: "none !important",
                        },
                      },
                    }}
                  />
                </div>

                <div className="d-flex justify-content-end mt-4 gap-4">
                  <Button
                    className="adocr_cancel_csr"
                    onClick={() => handleCloseModal()}
                  >
                    {" "}
                    Cancel
                  </Button>

                  {changeState_loading ? (
                    <div className="adocr_add_btn_csr">
                      {" "}
                      <CircularProgress
                        style={{ color: "#00F0FF" }}
                        size={30}
                      />
                    </div>
                  ) : (
                    <div
                      className="adocr_add_btn_csr"
                      onClick={() => submitCompleteOrder()}
                    >
                      {" "}
                      {SvgButtonCSR({text:'COMPLETE'})}
                    </div>
                  )}
                </div>
              </Box>
            </CsrModal>
            <CsrModal
              open={showStatusFailedModal}
              onClose={() => handleCloseModal()}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="d-flex justify-content-between align-items-center">
                  <span className="csr_create_order_header_titles">
                    Cancel Order?
                  </span>

                  <IconButton onClick={() => handleCloseModal()}>
                    <X color="#FFFFFF" />
                  </IconButton>
                </div>

                <div className="mt-2">
                  <InputLabelCsr
                    label="Reason for Cancellation"
                    varName={note}
                  />
                  <InputCompTextAreaCsr
                    name="note"
                    value={note}
                    setValues={setValues}
                    className="csr_text_area"
                  />
                </div>

                <div className="d-flex justify-content-end mt-4 gap-4">
                  <Button
                    className="adocr_cancel_csr"
                    onClick={() => handleCloseModal()}
                  >
                    {" "}
                    Cancel
                  </Button>

                  {changeState_loading ? (
                    <div className="adocr_add_btn_csr">
                      {" "}
                      <CircularProgress
                        style={{ color: "#00F0FF" }}
                        size={30}
                      />
                    </div>
                  ) : (
                    <div
                      className="adocr_add_btn_csr"
                      onClick={() => handleChangeStatusFailed()}
                    >
                      {" "}
                      {SvgButtonCSR({text:'CANCEL ORDER'})}
                    </div>
                  )}
                </div>
              </Box>
            </CsrModal>
          </div>
        </ThemeProvider>
      )}

      {!stateData ||
        (Object.keys(stateData).length === 0 && (
          <div>No Order details found</div>
        ))}

      {AlertErrorCsr(errorPosition, errorText, setValues)}
      {FinishCookingPrint({ data: stateData })}
    </>
  );
};

export const CsrDashboard = () => {
  const [showCreateOrder, setShowCreateOrder] = useState(true);
  const [showEditOrder, setShowEditOrder] = useState(false);
  const [showViewOrder, setShowViewOrder] = useState(false);
  const [refetchEdit, setRefetchEdit] = useState(false);
  const [fetchOrder, setFetchOrder] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [fetchDraft, setFetchDraft] = useState(true);
  const [view, setView] = useState(false)
  const ipadPort = (window.matchMedia("(min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait)").matches)
  const TabCsrView = () => {
      return <>
        <div className="csr_dashboard_container_2">
        {showCreateOrder ? <CreateOrder setFetchOrder={setFetchOrder} setView={setView} /> : null}
      {showEditOrder && currentId ? (
          <EditOrder
            currentId={currentId}
            setCurrentId={setCurrentId}
            setShowCreateOrder={setShowCreateOrder}
            setShowEditOrder={setShowEditOrder}
            setShowViewOrder={setShowViewOrder}
            setFetchOrder={setFetchOrder}
            setFetchDraft={setFetchDraft}
            refetchEdit={refetchEdit}
            setView={setView}
          />
        ) : null}        
       {showViewOrder && currentId ? (
          <ViewOrder
            currentId={currentId}
            setShowEditOrder={setShowEditOrder}
            setShowCreateOrder={setShowCreateOrder}
            setFetchOrder={setFetchOrder}
            setCurrentId={setCurrentId}
            setShowViewOrder={setShowViewOrder}
            setFetchDraft={setFetchDraft}
            setRefetchEdit={setRefetchEdit}
            setView={setView}
          />
          ) : null}
        </div>
      </>
    }

  return (
    <>
  {
    ipadPort && view ?
    <TabCsrView />
    :
      <div className="csr_dashboard_container">
     <div className="tab_fixed_table">
      <Table
          setShowViewOrder={setShowViewOrder}
          setShowCreateOrder={setShowCreateOrder}
          setShowEditOrder={setShowEditOrder}
          fetchOrder={fetchOrder}
          setFetchOrder={setFetchOrder}
          setCurrentId={setCurrentId}
          fetchDraft={fetchDraft}
          setFetchDraft={setFetchDraft}
          setView={setView}
        />
      </div>
      <div className="csr_dashboard_divider">
        <img src={getS3Image("divider.svg")} alt="divider" />
      </div>
      <div className="csr_dashboard_contents">
        {showCreateOrder ? <CreateOrder setFetchOrder={setFetchOrder} /> : null}
        {showEditOrder && currentId ? (
          <EditOrder
            currentId={currentId}
            setCurrentId={setCurrentId}
            setShowCreateOrder={setShowCreateOrder}
            setShowEditOrder={setShowEditOrder}
            setShowViewOrder={setShowViewOrder}
            setFetchOrder={setFetchOrder}
            setFetchDraft={setFetchDraft}
            refetchEdit={refetchEdit}
          />
        ) : null}

        {showViewOrder && currentId ? (
          <ViewOrder
            currentId={currentId}
            setShowEditOrder={setShowEditOrder}
            setShowCreateOrder={setShowCreateOrder}
            setFetchOrder={setFetchOrder}
            setCurrentId={setCurrentId}
            setShowViewOrder={setShowViewOrder}
            setFetchDraft={setFetchDraft}
            setRefetchEdit={setRefetchEdit}
          />
        ) : null}
      </div>
    </div>
    }
  </>
  );
};

const ShowOriginLogo = (origin) => {
  let imageSrc = getS3Image("otherOrder.svg"); // Default image source

  // Update image source based on origin or isAdminCreated
  if (origin === "admin") {
    imageSrc = getS3Image("otherOrder.svg");
  } else if (origin === "website") {
    imageSrc = getS3Image("novaOrder.svg");
  } else if (origin === "app") {
    imageSrc = getS3Image("NovaPurpleLogo.svg");
  } else if (origin === ORDER_ORIGIN.DAY_SALES) {
    imageSrc = sun
  } else if (origin === ORDER_ORIGIN.NIGHT_SALES) {
    imageSrc = moon
  }
  else {
    imageSrc = getS3Image("novaOrder.svg");
  }

  return <img src={imageSrc} alt="nova_order" />;
};
